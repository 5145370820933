import React, {Component} from 'react';
import {bindActionCreators} from "redux";
import {updateCollection, updateNavigationParam, updateUser} from "../../redux/systemSlice";
import {connect} from "react-redux";
import Utils, {withRouterHOC} from "../../utils";
import PreviewProduct from "../../app/components/previewProduct/previewProduct";

import './catalog.css';
import API from "../../app/api";

class CatalogBreweryProducts extends Component {
    constructor(props) {
        super(props);

        this.state = {
            openSubcategoriesFull: false,
            catalogType: null,
            catalogSlug: null,
            page: 1,
            loadingPage: false,
            lastLoadedProducts: 20,
        }

        this.openNewCollection = (catalogType, catalogSlug) => {
            try {
                this.props.updateCollection({products: []})
                API.categoryEntity(catalogType, catalogSlug)
                    .then(data => {
                        this.props.updateCollection(data.collection)

                        Utils.setPageTitle(`${data.collection.name} | ПабХаб`)
                    })

                this.props.updateNavigationParam({
                    page: 'catalog',
                    catalogType: catalogType,
                    catalogSlug: catalogSlug,
                })

                this.setState({
                    catalogType: catalogType,
                    catalogSlug: catalogSlug
                })

                this.setState({
                    catalogSlug: catalogSlug,
                    openSubcategoriesFull: false,
                    page: 1,
                    loadingPage: false,
                    lastLoadedProducts: 20
                })
            } catch (e) {
            }
        }

        this.updateCollection = (collection) => {
            this.props.updateCollection(collection)
        }

        this.paginationNext = (catalogType, catalogSlug) => {
            if (!this.state.loadingPage && this.state.lastLoadedProducts >= 20) {
                this.setState({page: this.state.page + 1, loadingPage: true}, () => {
                    API.categoryEntityProducts(catalogType, catalogSlug, this.state.page)
                        .then(data => {
                            let collection = Object.assign({}, this.props.collection)
                            let newProducts = Array.from(collection.products)
                            data.products.forEach(product => {
                                newProducts.push(product)
                            })
                            collection.products = newProducts
                            this.updateCollection(collection)
                            this.setState({loadingPage: false, lastLoadedProducts: data.products.length})
                        })
                })
            }
        }

        this.handleProductsScroll = () => {
            if ((window.innerHeight + Math.round(window.scrollY)) >= document.body.offsetHeight) {
                this.paginationNext(this.state.catalogType, this.state.catalogSlug)
                this.setState({showScrollUpButton: true})
            }
        }

        window.onscroll = this.handleProductsScroll
    }

    componentDidMount() {
        this.openNewCollection('brewery', this.props.params.catalogSlug)
    }

    render() {
        if (this.props.params.catalogSlug !== this.state.catalogSlug) {
            this.openNewCollection('brewery', this.props.params.catalogSlug)
        }

        return (
            <div className='catalog-container'>
                <div className='catalog'>
                    <div className='name'>
                        {this.props.collection.name}
                    </div>
                    <div className='products'>
                        {
                            this.props.collection.products.map(product =>
                                <PreviewProduct key={product.name} product={product}/>
                            )
                        }
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = (state, ownProps) => ({
    categories: state.system.categories,
    collection: state.system.collection
})

const mapDispatchToProps = (dispatch, ownProps) => {
    return bindActionCreators(
        {
            updateUser: updateUser,
            updateCollection: updateCollection,
            updateNavigationParam: updateNavigationParam,
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouterHOC(CatalogBreweryProducts));