import React, {Component} from 'react';
import {bindActionCreators} from "redux";
import {updateCollection, updateNavigationParam, updateShop, updateUser} from "../../redux/systemSlice";
import {connect} from "react-redux";
import Utils, {generateTaxiUrl, safetyExternalUrl, withRouterHOC} from "../../utils";

import './catalog.css';
import API from "../../app/api";
import {Link} from "react-router-dom";
import PreviewCollection from "../../app/components/previewCollection/previewCollection";

import TaxiIcon from "../../images/taxiIcon.png";


class CatalogShopDetail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            openSubcategoriesFull: false,
            shopSlug: null
        }

        this.openNewCollection = (shopSlug) => {
            this.props.updateShop({products: []})
            API.shopEntity(shopSlug)
                .then(data => {
                    Utils.setPageTitle(`${data.store.public_name} | ПабХаб`)

                    this.props.updateShop(data.store)
                })

            this.props.updateNavigationParam({
                page: 'catalog',
                catalogType: 'shop',
                shopSlug: shopSlug,
            })

            this.setState({
                shopSlug: shopSlug
            })
        }
    }

    componentDidMount() {
        this.openNewCollection(this.props.params.shopSlug)
    }

    render() {
        if (this.props.params.shopSlug !== this.state.shopSlug) {
            this.openNewCollection(this.props.params.shopSlug)
        }

        return (
            <div className='catalog-container'>
                <div className='catalog'>
                    <div className='breadcrumbs'>
                        <div className='item'>
                            <Link to='/catalog'>Каталог</Link>
                        </div>
                        <span>•</span>
                        <div className='item last-item'>
                            {this.props.shop.public_name}
                        </div>
                    </div>
                    <div className='brewery-content'>
                        <div className='brewery-card'>
                            <div className='top-container'>
                                {
                                    this.props.shop.public_image_url ?
                                        <div className='avatar'>
                                            <img src={this.props.shop.public_image_url} alt=''/>
                                        </div> : null
                                }

                                <div className='top'>
                                    <div className='name'>
                                        {this.props.shop.public_name}
                                    </div>
                                    <div className='address'>
                                        {this.props.shop.public_address}
                                    </div>
                                    {/*<div className='score-container'>*/}
                                    {/*    <div className='score'>*/}
                                    {/*        <StarBlueIcon/>*/}
                                    {/*        {this.props.shop.score}*/}
                                    {/*    </div>*/}
                                    {/*    <div className='reviews'>{this.props.shop.reviews} отзывов</div>*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                            <div className='buttons'>
                                {
                                    this.props.shop.public_website ?
                                        <a className='button'
                                           href={safetyExternalUrl(this.props.shop.public_website)}
                                           target='_blank'>
                                            Перейти на сайт
                                        </a>
                                        : null
                                }

                                <div className='buttons-horizontal'>
                                    {
                                        this.props.shop.public_map_url ?
                                            <a className='button blue'
                                               href={safetyExternalUrl(this.props.shop.public_map_url)} target='_blank'>
                                                Показать на карте
                                            </a>
                                            : null
                                    }
                                    {
                                        this.props.shop.public_coords ?
                                            <a className='button taxi'
                                               href={generateTaxiUrl(this.props.shop.public_coords)}
                                               target='_blank'>
                                                <img src={TaxiIcon} alt='taxi'/>
                                                Вызвать такси
                                            </a>
                                            : null
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='brewery-products'>
                            <PreviewCollection
                                collectionName='Меню'
                                productsCount={this.props.shop.products_total}
                                catalogType='shop'
                                collection={this.props.shop}
                                customUrl={`/shop/${this.props.shop.slug}/menu`}
                                limit={3}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = (state, ownProps) => ({
    categories: state.system.categories,
    collection: state.system.collection,
    shop: state.system.shop,
})

const mapDispatchToProps = (dispatch, ownProps) => {
    return bindActionCreators(
        {
            updateUser: updateUser,
            updateCollection: updateCollection,
            updateNavigationParam: updateNavigationParam,
            updateShop: updateShop,
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouterHOC(CatalogShopDetail));