import React, {Component} from 'react';
import {bindActionCreators} from "redux";
import {updateNavigationParam, updateProduct, updateProfile, updateUser} from "../../redux/systemSlice";
import {connect} from "react-redux";
import Utils, {safetyName, withRouterHOC} from "../../utils";

import API from "../../app/api";

import {SERVER_URL} from "../../config";
import Review from "../../app/components/review/review";
import ModalComponent from "../../app/components/modalComponent/modalComponent";
import ImageUploader from "../../app/components/imageUploader/imageUploader";

import {ReactComponent as SuccessBigIcon} from "../../images/successBig.svg";

import './user.css'


class UserProfile extends Component {
    constructor(props) {
        super(props);

        this.state = {
            username: null,

            isModalProfileEditOpen: false,
            isModalChangePasswordOpen: false,

            page: 1,
            loadingPage: false,
            lastLoadedItems: 10,

            editProfileName: null,
            editProfileImageBase64: null,

            oldPassword: null,
            newPassword: null,

            changePasswordSuccess: false,
            changePasswordErrorText: null
        }

        this.setModalProfileEditStatus = (status) => {
            if (!status) {
                this.setState({
                    editProfileName: safetyName(this.props.user),
                    editProfileImageBase64: null
                })
            }
            this.setState({isModalProfileEditOpen: status})
        }

        this.setModalChangePasswordStatus = (status) => {
            this.setState({isModalChangePasswordOpen: status})
        }

        this.openProfile = (userSlug) => {
            this.props.updateProfile({reviews: []})
            API.userProfile(userSlug)
                .then(data => {
                    this.setState({
                        lastLoadedItems: data.user.reviews.length,
                        editProfileName: safetyName(this.props.user)
                    })
                    Utils.setPageTitle(`${data.user.first_name} | ПабХаб`)
                    this.props.updateProfile(data.user)
                })

            this.props.updateNavigationParam({
                page: 'user',
                userSlug: userSlug,
            })

            this.setState({
                username: userSlug
            })
        }

        this.sendProfileEdit = () => {
            API.profileEdit(this.state.editProfileName, this.state.editProfileImageBase64)
                .then(data => {
                    data.authorize = true;
                    this.props.updateUser(data)
                    window.location.reload();
                })
        }

        this.sendChangePassword = () => {
            this.setState({changePasswordErrorText: null})
            API.profileChangePassword(this.state.oldPassword, this.state.newPassword)
                .then(data => {
                    this.setState({changePasswordSuccess: true})
                    setTimeout(() => window.location.reload(), 2000)
                })
                .catch(error => {
                    this.setState({changePasswordErrorText: 'Старый пароль не подходит'})
                })
        }

        this.paginationNext = (userSlug) => {
            if (!this.state.loadingPage && this.state.lastLoadedItems >= 10) {
                this.setState({page: this.state.page + 1, loadingPage: true}, () => {
                    API.userProfileReviews(userSlug, this.state.page)
                        .then(data => {
                            let profile = Object.assign({}, this.props.profile)
                            let reviews = profile.reviews.slice()
                            let newReviews = Array.from(reviews)
                            data.reviews.forEach(review => {
                                newReviews.push(review)
                            })
                            profile.reviews = newReviews
                            this.props.updateProfile(profile)
                            this.setState({loadingPage: false, lastLoadedItems: data.reviews.length})
                        })
                })
            }
        }

        this.handleProductsScroll = () => {
            if ((window.innerHeight + Math.round(window.scrollY)) >= document.body.offsetHeight) {
                this.paginationNext(this.props.params.userSlug)
                this.setState({showScrollUpButton: true})
            }
        }

        window.onscroll = this.handleProductsScroll
    }

    componentDidMount() {
        this.openProfile(this.props.params.userSlug)

        Utils.setPageTitle(`Профиль | ПабХаб`)
    }

    render() {
        if (this.props.params.userSlug !== this.state.username) {
            this.openProfile(this.props.params.userSlug)
        }

        return (
            <div className='user-profile-container'>
                <div className='user-profile'>
                    <div className='breadcrumbs'>
                        <div className='item'>
                            <div>Пользователи</div>
                        </div>
                        <span>•</span>
                        <div className='item last-item'>
                            {safetyName(this.props.profile)}
                        </div>
                    </div>
                    <div className='user-profile-content'>
                        <div className='user-profile-card'>
                            <div className='top'>
                                <div className='avatar'>
                                    <img src={this.props.profile && this.props.profile.avatar_url ?
                                        this.props.profile.avatar_url :
                                        'https://media.pubhub.su/assets/images/avatar-default.png'}
                                         alt='avatar'/>
                                </div>
                                <div className='content'>
                                    <div className='name'>
                                        {safetyName(this.props.profile)}
                                    </div>
                                    <div className='reviews-count'>
                                        Оставил(а) {this.props.profile.reviews_count} отзывов
                                    </div>
                                </div>
                            </div>
                            {
                                this.props.user.authorize && this.props.user.username === this.props.profile.username ?
                                    <div className='buttons'>
                                        <div className='button' onClick={() => this.setModalProfileEditStatus(true)}>
                                            Редактировать профиль
                                        </div>
                                    </div> : null
                            }
                        </div>
                        <div className='user-reviews'>
                            <div className='title-container'>
                                <div className='title'>Посты</div>
                                <div className='count'>{this.props.profile.reviews_count}</div>
                            </div>
                            <div className='reviews'>
                                {
                                    this.props.profile.reviews.map((review, index) => {
                                        return <Review key={index} review={review}/>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <ModalComponent isOpen={this.state.isModalProfileEditOpen}
                                onClose={() => this.setModalProfileEditStatus(false)}>
                    <div className="user-profile-modal">
                        <ImageUploader
                            title='Изображение профиля'
                            url={this.props.user.avatar_url}
                            imageBase64={this.state.editProfileImageBase64}
                            uploadHandler={(b64string) => this.setState({editProfileImageBase64: b64string})}
                            description='.png, .jpeg до 10мб'
                        />
                        <div className='name-input'>
                            <div className='name-input-label'>Имя пользователя</div>
                            <input placeholder='Как вас зовут?'
                                   value={this.state.editProfileName}
                                   onChange={(e) => this.setState({editProfileName: e.target.value})}/>
                        </div>
                        <div className='buttons'>
                            <div className='button' onClick={() => {
                                this.setModalProfileEditStatus(false);
                                this.setModalChangePasswordStatus(true);
                            }}>Сменить пароль
                            </div>
                        </div>
                        <div className='buttons'>
                            <div className='button blue' onClick={() => this.sendProfileEdit()}>
                                Сохранить
                            </div>
                            <div className='button' onClick={() => this.setModalProfileEditStatus(false)}>
                                Отмена
                            </div>
                        </div>
                    </div>
                </ModalComponent>

                <ModalComponent isOpen={this.state.isModalChangePasswordOpen}
                                onClose={() => this.setModalChangePasswordStatus(false)}>
                    <div className="user-profile-modal">
                        <div className='name-input'>
                            <div className='name-input-label'>Старый пароль</div>
                            <input value={this.state.oldPassword} type='password'
                                   onChange={(e) => this.setState({oldPassword: e.target.value})}/>
                        </div>
                        <div className='name-input'>
                            <div className='name-input-label'>Новый пароль</div>
                            <input value={this.state.newPassword} type='password'
                                   onChange={(e) => this.setState({newPassword: e.target.value})}/>
                        </div>
                        {
                            this.state.changePasswordSuccess ? <div className='buttons'>
                                    <div className='success'>
                                        <SuccessBigIcon/>
                                    </div>
                                    <div className='label'>
                                        Новый пароль установлен
                                    </div>
                                </div> :
                                <div className='buttons'>
                            <div className='button blue' onClick={() => this.sendChangePassword()}>
                                Сменить пароль
                            </div>
                            <div className='button' onClick={() => this.setModalChangePasswordStatus(false)}>
                                Отмена
                            </div>
                        </div>
                        }
                        {this.state.changePasswordErrorText ? <div className='error'>
                            {this.state.changePasswordErrorText}
                        </div> : null}
                    </div>
                </ModalComponent>
            </div>
        );
    }
}


const mapStateToProps = (state, ownProps) => ({
    user: state.system.user,
    profile: state.system.profile,
    product: state.system.product,
    categories: state.system.categories
})

const mapDispatchToProps = (dispatch, ownProps) => {
    return bindActionCreators(
        {
            updateUser: updateUser,
            updateProfile: updateProfile,
            updateProduct: updateProduct,
            updateNavigationParam: updateNavigationParam,
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouterHOC(UserProfile));