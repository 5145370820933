import React, {Component} from 'react';
import {bindActionCreators} from "redux";
import {updateCategories, updateNavigationParam, updateUser} from "../../redux/systemSlice";
import {connect} from "react-redux";

import {ReactComponent as ClearSearchIcon} from "../../images/clearSearch.svg";

import './search.css';
import API from "../../app/api";
import PreviewProduct from "../../app/components/previewProduct/previewProduct";
import Utils from "../../utils";


class Search extends Component {
    constructor(props) {
        super(props);

        this.state = {
            searchQuery: null,
            page: 1,
            products: null,
            total: 0,

            lastLoadedProducts: 20,
            loadingPage: false,
            showScrollUpButton: false
        }

        this.getSearch = () => {
            API.search(this.state.searchQuery, this.state.page)
                .then(data => {
                    this.setState({
                        page: 1,
                        products: data.products,
                        total: data.total
                    })
                })
        }

        this.paginationNext = () => {
            if (!this.state.loadingPage && this.state.lastLoadedProducts >= 20) {
                this.setState({page: this.state.page + 1, loadingPage: true}, () => {
                    API.search(this.state.searchQuery, this.state.page)
                        .then(data => {
                            let newProducts = Array.from(this.state.products === null ? [] : this.state.products)
                            data.products.forEach(product => {
                                newProducts.push(product)
                            })
                            this.setState({
                                page: this.state.page,
                                products: newProducts,
                                total: data.total,
                                loadingPage: false,
                                lastLoadedProducts: data.products.length
                            })
                        })
                })
            }
        }

        this.handleProductsScroll = () => {
            if ((window.innerHeight + Math.round(window.scrollY)) >= document.body.offsetHeight) {
                this.paginationNext()
                this.setState({showScrollUpButton: true})
            }
        }

        this.handleKeyPress = (event) => {
            if (event.key === 'Enter' || event.keyCode === 13) {
                this.getSearch()
            }
        }

        window.onscroll = this.handleProductsScroll
    }

    componentDidMount() {
        this.props.updateNavigationParam({
            page: 'search',
        })

        Utils.setPageTitle(`Поиск | ПабХаб`)

        try {
            document.getElementById('search-input').focus()
        } catch (e) {

        }
    }

    render() {
        return (
            <div className='search-container' onKeyDown={this.handleKeyPress}>
                <div className='search'>
                    <div className='name'>Поиск</div>
                    <div className='content'>
                        <div className='search-component'>
                            <input id='search-input'
                                   value={this.state.searchQuery}
                                   placeholder='Введите поисковый запрос...'
                                   onChange={(e) => this.setState({searchQuery: e.target.value})}/>
                            {
                                this.state.searchQuery ?
                                    <div className='clear' onClick={() => this.setState({searchQuery: ''})}>
                                        <ClearSearchIcon/>
                                    </div> : null
                            }
                            <div className='button' onClick={() => this.getSearch()}>
                                Поиск
                            </div>
                        </div>
                        {
                            this.state.products !== null ?
                                <div className='products-container'>
                                    <div className='label'>Нашли {this.state.total} результатов</div>
                                    <div className='products'>
                                        {
                                            this.state.products.map(product =>
                                                <PreviewProduct key={product.name} product={product}/>
                                            )
                                        }
                                    </div>
                                </div>
                                : null
                        }
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = (state, ownProps) => ({
    categories: state.system.categories
})

const mapDispatchToProps = (dispatch, ownProps) => {
    return bindActionCreators(
        {
            updateCategories: updateCategories,
            updateUser: updateUser,
            updateNavigationParam: updateNavigationParam,
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Search);