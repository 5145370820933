import React, {Component} from 'react';
import {bindActionCreators} from "redux";
import {updateNavigationParam, updatePartnerUser, updateUser} from "../../redux/systemSlice";
import {connect} from "react-redux";
import Utils, {withRouterHOC} from "../../utils";
import Storage from '../../utils/storage';
import {Link} from "react-router-dom";
import API from "../../app/api";
import {withCookies} from "react-cookie";

import './login.css';

class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: null,
            password: null,

            activeTab: 'portal',
            tabs: {
                portal: 'на портал',
                partners: 'партнерам'
            },

            tokenPortal: null,
            tokenPartners: null,

            errorText: null
        }

        this.logout = () => {
            if (this.state.activeTab === 'portal') {
                this.props.cookies.remove('portalToken', {path: '/'})
                this.props.updateUser({authorize: false})
            } else {
                this.props.cookies.remove('token', {path: '/'})
                this.props.updatePartnerUser({authorize: false})
            }
        }

        this.authorize = (callback) => {
            this.setState({errorText: null})

            let email = this.state.email;
            let password = this.state.password
            API.csrf().then(data => {
                Storage.set('csrf', data.csrf_token)

                if (this.state.activeTab === 'portal') {
                    API.login(email, password)
                        .then(data => {
                            const jwtToken = data.token;
                            this.props.cookies.set('portalToken', jwtToken, {path: '/'})
                            Storage.set('portalToken', jwtToken)
                            callback(true)
                            API.profile()
                                .then(data => {
                                    Storage.set('portalUser', data)
                                    data.authorize = true
                                    this.props.updateUser(data)
                                })
                                .catch(e => {
                                    this.props.updateUser({authorize: false})
                                })
                        })
                        .catch(e => {
                            this.setState({errorText: 'Неверный email или пароль'})
                            callback(false)
                        })
                } else {
                    API.loginPartner(email, password)
                        .then(data => {
                            const jwtToken = data.token;
                            this.props.cookies.set('token', jwtToken, {path: '/'})
                            Storage.set('token', jwtToken)
                            callback(true)
                            API.profilePartner()
                                .then(data => {
                                    Storage.set('user', data)
                                    data.authorize = true
                                    this.props.updatePartnerUser(data)
                                })
                                .catch(e => {
                                    this.props.updatePartnerUser({authorize: false})
                                })
                        })
                        .catch(e => {
                            this.setState({errorText: 'Неверный email или пароль'})
                            callback(false)
                        })
                }
            })
                .catch(e => console.error(e))
        }

        this.authNext = (status) => {
            console.log(status)
        }
    }

    componentDidMount() {
        this.props.updateNavigationParam({
            page: 'login',
        })

        Utils.setPageTitle(`Авторизация | ПабХаб`)
    }

    render() {
        let portalUser = Storage.get('portalUser')
        let partnerUser = Storage.get('user')

        const isPortalAuthorize = this.state.activeTab === 'portal' && this.props.user.authorize;
        const isPartnersAuthorize = this.state.activeTab === 'partners' && this.props.partnerUser.authorize;

        return (
            <div className='auth-container'>
                <div className='auth'>
                    <div className='auth-form'>
                        <div className='card'>
                            <div className='header'>
                                <div className='label'>Вход</div>
                                <div className='slider'>
                                    {
                                        Object.keys(this.state.tabs).map(item => {
                                            let classElement = ['element']
                                            if (item === this.state.activeTab) classElement.push('active')
                                            return <div key={this.state.tabs[item]} className={classElement.join(' ')}
                                                        onClick={() => this.setState({activeTab: item})}>
                                                {this.state.tabs[item]}
                                            </div>
                                        })
                                    }
                                </div>
                            </div>
                            {
                                isPortalAuthorize ? <>
                                    <div className='authorize'>
                                        <div className='label'>Вы вошли как</div>
                                        <div className='email'>{portalUser.email}</div>
                                    </div>
                                    <Link className='button' to={`/user/${portalUser.username}`}>
                                        Продолжить
                                    </Link>
                                </> : null
                            }
                            {
                                isPartnersAuthorize ? <>
                                    <div className='authorize'>
                                        <div className='label'>Вы вошли как</div>
                                        <div className='email'>{partnerUser.email}</div>
                                    </div>
                                    <a className='button' href='/cabinet/'>
                                        Продолжить
                                    </a>
                                </> : null
                            }
                            {
                                !isPortalAuthorize && !isPartnersAuthorize ? <>
                                    <div className='inputs'>
                                        <div className='input'>
                                            <input placeholder='Почта' type='email' value={this.state.email}
                                                   onChange={(e) => this.setState({email: e.target.value})}/>
                                        </div>
                                        <div className='input'>
                                            <input placeholder='Пароль' type='password' value={this.state.password}
                                                   onChange={(e) => this.setState({password: e.target.value})}/>
                                        </div>
                                    </div>
                                    {
                                        this.state.errorText ?
                                            <div className='error'>{this.state.errorText}</div> : null
                                    }
                                    <div className='button' onClick={() => this.authorize(this.authNext)}>
                                        Войти
                                    </div>
                                </> : null
                            }
                        </div>
                        <div className='extra'>
                            {
                                isPortalAuthorize || isPartnersAuthorize ?
                                    <div className='register link' onClick={() => this.logout()}>
                                        Выйти из аккаунта
                                    </div>
                                    : <>
                                        <Link className='register link' to='/register'>Регистрация</Link>
                                        <Link className='forgot link' to='/forgot'>Забыли пароль?</Link>
                                    </>
                            }

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = (state, ownProps) => ({
    user: state.system.user,
    partnerUser: state.system.partnerUser,
})

const mapDispatchToProps = (dispatch, ownProps) => {
    return bindActionCreators(
        {
            updateUser: updateUser,
            updatePartnerUser: updatePartnerUser,
            updateNavigationParam: updateNavigationParam
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouterHOC(withCookies(Login)));