import React, {useEffect, useState} from 'react';
import './animations.css';

const FadeComponent = ({children, isVisible}) => {
    const [shouldRender, setShouldRender] = useState(isVisible);
    const [animationClass, setAnimationClass] = useState('');

    useEffect(() => {
        let timeoutId;

        if (isVisible) {
            if (!shouldRender) {
                setShouldRender(true);
            }
            setAnimationClass('fade-in');
        } else {
            setAnimationClass('fade-out');
            timeoutId = setTimeout(() => {
                setShouldRender(false);
            }, 500);
        }

        return () => {
            if (timeoutId) clearTimeout(timeoutId);
        };
    }, [isVisible]);

    if (!shouldRender) {
        return null;
    }

    return (
        <div className={`fade ${animationClass}`}>
            {children}
        </div>
    );
};


export default FadeComponent;