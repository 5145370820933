import React from "react";

import './scoreComponent.css'

function reviewLabel(reviews) {
    const lastDigit = reviews % 10;
    const lastTwoDigits = reviews % 100;

    if (lastTwoDigits >= 11 && lastTwoDigits <= 14) {
        return `${reviews} отзывов`;
    } else if (lastDigit === 1) {
        return `${reviews} отзыв`;
    } else if (lastDigit >= 2 && lastDigit <= 4) {
        return `${reviews} отзыва`;
    } else {
        return `${reviews} отзывов`;
    }
}

function ScoreComponent({rating, reviews, isBigReviewScore, isMiniReviewScore}) {
    let ratingClass = ['score']
    if (rating === null) ratingClass.push('empty')
    else if (rating < 5) ratingClass.push('bad')
    else if (rating >= 5 && rating < 7.5) ratingClass.push('middle')
    else if (rating >= 7.5) ratingClass.push('good')

    if (isMiniReviewScore) {
        return <div className='score-component'>
            <div className={ratingClass.join(' ')}>
                {rating === null ? '—' : (Math.round(rating * 10) / 10).toFixed(1)}
            </div>
        </div>
    }

    if (isBigReviewScore) {
        ratingClass.push('big')

        return <div className='score-component'>
            <div className={ratingClass.join(' ')}>
                {rating === null ? '—' : parseInt((Math.round(rating * 10) / 10).toFixed(1))}
            </div>
        </div>
    }

    return <div className='score-component'>
        <div className={ratingClass.join(' ')}>
            {rating === null ? '—' : (Math.round(rating * 10) / 10).toFixed(1)}
        </div>
        {
            reviews ?
                <div className='reviews'>{reviewLabel(reviews)}</div>
                :
                <div className='reviews'>нет отзывов</div>
        }
    </div>
}

export default ScoreComponent;