import React, {Component} from 'react';
import {bindActionCreators} from "redux";
import {updateCollection, updateNavigationParam, updateUser} from "../../redux/systemSlice";
import {connect} from "react-redux";
import Utils, {withRouterHOC} from "../../utils";
import PreviewProduct from "../../app/components/previewProduct/previewProduct";

import './catalog.css';
import API from "../../app/api";
import {Link} from "react-router-dom";

class CatalogCollection extends Component {
    constructor(props) {
        super(props);

        this.state = {
            openSubcategoriesFull: false,
            catalogSlug: null,
            page: 1,
            loadingPage: false,
            lastLoadedProducts: 20,
        }

        this.openNewCollection = (catalogType, catalogSlug) => {
            this.props.updateCollection({products: []})
            API.categoryEntity(catalogType, catalogSlug)
                .then(data => {
                    this.props.updateCollection(data.collection)
                    Utils.setPageTitle(`${data.collection.name} | ПабХаб`)
                    this.setState({lastLoadedProducts: data.collection.products.length})
                })

            this.props.updateNavigationParam({
                page: 'catalog',
                catalogType: catalogType,
                catalogSlug: catalogSlug,
            })

            this.setState({
                catalogSlug: catalogSlug,
                openSubcategoriesFull: false,
                page: 1,
                loadingPage: false,
                lastLoadedProducts: 20
            })
        }

        this.updateCollection = (collection) => {
            this.props.updateCollection(collection)
        }

        this.paginationNext = (catalogType, catalogSlug) => {
            if (!this.state.loadingPage && this.state.lastLoadedProducts >= 20) {
                this.setState({page: this.state.page + 1, loadingPage: true}, () => {
                    API.categoryEntityProducts(catalogType, catalogSlug, this.state.page)
                        .then(data => {
                            let collection = Object.assign({}, this.props.collection)
                            let newProducts = Array.from(collection.products)
                            data.products.forEach(product => {
                                newProducts.push(product)
                            })
                            collection.products = newProducts
                            this.updateCollection(collection)
                            this.setState({loadingPage: false, lastLoadedProducts: data.products.length})
                        })
                })
            }
        }

        this.handleProductsScroll = () => {
            if ((window.innerHeight + Math.round(window.scrollY)) >= document.body.offsetHeight) {
                this.paginationNext(this.props.params.catalogType, this.props.params.catalogSlug)
                this.setState({showScrollUpButton: true})
            }
        }

        window.onscroll = this.handleProductsScroll
    }

    componentDidMount() {
        this.openNewCollection(this.props.params.catalogType, this.props.params.catalogSlug)
    }

    render() {
        if (this.props.params.catalogSlug !== this.state.catalogSlug) {
            this.openNewCollection(this.props.params.catalogType, this.props.params.catalogSlug)
        }

        const catalogType = this.props.params.catalogType

        let subcategories = []

        return (
            <div className='catalog-container'>
                <div className='catalog'>
                    <div className='name'>
                        {this.props.collection.parent ?
                            <Link to={`/catalog/${catalogType}/${this.props.collection.parent.slug}/`}>
                                {this.props.collection.parent.name}
                            </Link> : null}
                        {this.props.collection.name}
                    </div>
                    {
                        catalogType === 'style' && this.props.collection && this.props.collection.parent_id === null ?
                            <div className='sub-styles'>
                                {
                                    this.props.collection.subcategories.length > 8 ? (
                                        this.state.openSubcategoriesFull ? this.props.collection.subcategories.map(((item, index) => {
                                                return <Link key={index} className='sub-style'
                                                             onClick={() => this.openNewCollection(this.props.params.catalogType, item.slug)}
                                                             to={`/catalog/${catalogType}/${item.slug}/`}>
                                                    {item.name}
                                                </Link>
                                            })) :
                                            this.props.collection.subcategories.slice(0, 8).map(((item, index) => {
                                                return <Link key={index} className='sub-style'
                                                             onClick={() => this.openNewCollection(this.props.params.catalogType, item.slug)}
                                                             to={`/catalog/${catalogType}/${item.slug}/`}>
                                                    {item.name}
                                                </Link>
                                            }))
                                    ) : (
                                        this.props.collection.subcategories.map(((item, index) => {
                                            return <Link key={index} className='sub-style'
                                                         onClick={() => this.openNewCollection(this.props.params.catalogType, item.slug)}
                                                         to={`/catalog/${catalogType}/${item.slug}/`}>
                                                {item.name}
                                            </Link>
                                        }))
                                    )
                                }
                                {
                                    !this.state.openSubcategoriesFull && this.props.collection.subcategories.length > 8 ?
                                        <div className='sub-style-button'
                                             onClick={() => this.setState({openSubcategoriesFull: true})}>
                                            Показать ещё {this.props.collection.subcategories.length - 8}
                                        </div> : null
                                }
                            </div> : null
                    }
                    <div className='products'>
                        {
                            this.props.collection.products.map(product =>
                                <PreviewProduct key={product.name} product={product}/>
                            )
                        }
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = (state, ownProps) => ({
    categories: state.system.categories,
    collection: state.system.collection
})

const mapDispatchToProps = (dispatch, ownProps) => {
    return bindActionCreators(
        {
            updateUser: updateUser,
            updateCollection: updateCollection,
            updateNavigationParam: updateNavigationParam,
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouterHOC(CatalogCollection));