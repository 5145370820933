import React, {Component} from 'react';
import {bindActionCreators} from "redux";
import {updateCollection, updateNavigationParam, updateUser} from "../../redux/systemSlice";
import {connect} from "react-redux";
import Utils, {withRouterHOC} from "../../utils";

import './catalog.css';
import API from "../../app/api";
import {Link} from "react-router-dom";
import PreviewCollection from "../../app/components/previewCollection/previewCollection";
import ScoreComponent from "../../app/components/scoreComponent/scoreComponent";

class CatalogBreweryDetail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            openSubcategoriesFull: false,
            catalogSlug: null
        }

        this.openNewCollection = (catalogType, catalogSlug) => {
            this.props.updateCollection({products: []})
            API.categoryEntity(catalogType, catalogSlug)
                .then(data => {
                    this.props.updateCollection(data.collection)

                    Utils.setPageTitle(`${data.collection.name} | ПабХаб`)
                })

            this.props.updateNavigationParam({
                page: 'catalog',
                catalogType: catalogType,
                catalogSlug: catalogSlug,
            })

            this.setState({
                catalogSlug: catalogSlug
            })
        }
    }

    componentDidMount() {
        this.openNewCollection('brewery', this.props.params.catalogSlug)
    }

    render() {
        if (this.props.params.catalogSlug !== this.state.catalogSlug) {
            this.openNewCollection('brewery', this.props.params.catalogSlug)
        }

        return (
            <div className='catalog-container'>
                <div className='catalog'>
                    <div className='breadcrumbs'>
                        <div className='item'>
                            <Link to='/catalog'>Каталог</Link>
                        </div>
                        <span>•</span>
                        <div className='item last-item'>
                            {this.props.collection.name}
                        </div>
                    </div>
                    <div className='brewery-content'>
                        <div className='brewery-card'>
                            <div className='top-container'>
                                <div className='top'>
                                    <div className='name'>
                                        {this.props.collection.name}
                                    </div>
                                    <ScoreComponent
                                        rating={this.props.collection.average_rating}
                                        reviews={this.props.collection.review_count}
                                    />
                                </div>
                            </div>
                            {/*<div className='buttons'>*/}
                            {/*    <div className='button'>Перейти на сайт</div>*/}
                            {/*</div>*/}
                        </div>
                        <div className='brewery-products'>
                            <PreviewCollection
                                collectionName='Продукция'
                                productsCount={this.props.collection.products_total}
                                catalogType='brewery'
                                collection={this.props.collection}
                                limit={3}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = (state, ownProps) => ({
    categories: state.system.categories,
    collection: state.system.collection
})

const mapDispatchToProps = (dispatch, ownProps) => {
    return bindActionCreators(
        {
            updateUser: updateUser,
            updateCollection: updateCollection,
            updateNavigationParam: updateNavigationParam,
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouterHOC(CatalogBreweryDetail));