import React, {useState} from "react";
import './imageUploader.css';

import {ReactComponent as ClearIcon} from "../../../images/clear.svg";
import {ReactComponent as ReplaceIcon} from "../../../images/replace.svg";
import {Fingerprint} from "../../../utils/storage";


function ImageUploader({title, description, imageBase64, url, uploadHandler, size}) {
    const [internalId, setInternalId] = useState(Fingerprint.hashFunc(String(Math.random())))
    const [showUrl, setShowUrl] = useState(true)

    if (['small', 'large'].indexOf(size) < 0) {
        size = 'large'
    }

    const handleClick = () => {
        document.getElementById(`image-${internalId}`).click()
    };

    const fileInputChange = (event) => {
        const target = event.target;
        if (target.files && target.files[0]) {
            const file = target.files[0];
            const reader = new FileReader();
            reader.onload = (event) => {
                let base64String = event.target.result;
                if (uploadHandler) uploadHandler(base64String);
            };
            reader.readAsDataURL(file);
        }
    };

    let previewClass = ['ImageUploader-preview']
    if (size === 'small') previewClass.push('ImageUploader-preview-small')
    else if (size === 'large') previewClass.push('ImageUploader-preview-large')

    return <div className="ImageUploader">
        {title ? <div className="ImageUploader-title">{title}</div> : null}

        <div className={previewClass.join(' ')}>
            {
                imageBase64 || (showUrl && url) ? (
                    <>
                        <img alt='preview' src={imageBase64 ? imageBase64 : (showUrl ? url : null)}/>
                        <div className="ImageUploader-preview-buttons">
                            <div className="ImageUploader-preview-button"
                                 onClick={() => {
                                     document.getElementById(`image-${internalId}`).click()
                                 }}>
                                <ReplaceIcon/>
                                Заменить
                            </div>
                            <div className="ImageUploader-preview-button"
                                 onClick={() => {
                                     if (uploadHandler) {
                                         uploadHandler(null);
                                         setShowUrl(false)
                                     }
                                 }}>
                                <ClearIcon/>
                                Очистить
                            </div>
                        </div>
                    </>
                ) : null
            }
            <div
                className='ImageUploader-upload-button'
                style={{display: imageBase64 || (showUrl && url) ? 'none' : 'flex'}}
                onClick={handleClick}
            >
                Загрузить изображение
                <input
                    id={`image-${internalId}`}
                    name='image'
                    type='file'
                    accept='image/*'
                    className='ImageUploader-hidden-input'
                    onChange={(event) => fileInputChange(event)}
                />
            </div>
        </div>

        {description ? <div className="ImageUploader-description">{description}</div> : null}
    </div>
}

export default ImageUploader;