import React, {useEffect, useState} from "react";

import './sliderComponent.css'

const SliderComponent = ({items}) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [progress, setProgress] = useState(0);

    const goToPrevious = () => {
        setCurrentIndex((prevIndex) => (prevIndex === 0 ? items.length - 1 : prevIndex - 1));
        setProgress(0);
    };

    const goToNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex === items.length - 1 ? 0 : prevIndex + 1));
        setProgress(0);
    };

    const goToImage = (index) => {
        setCurrentIndex(index);
        setProgress(0);
    };

    useEffect(() => {
        const interval = setInterval(goToNext, 7000);

        const progressInterval = setInterval(() => {
            setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 100 / 14));
        }, 500);

        return () => {
            clearInterval(interval);
            clearInterval(progressInterval);
        };
    }, [currentIndex]);


    return (
        <div className="slider-component">
            <div className="progress-bar">
                <div className="progress" style={{ width: `${progress}%` }}></div>
            </div>
            <div className="slider-images"
                 style={{transform: `translateX(-${currentIndex * 100}%)`}}>
                {
                    items && items.map((item, index) => {
                        return <a className="slider-image" key={index} target="_blank" href={item.href}
                                    style={{backgroundImage: `url(${item.image})`}}></a>
                    })
                }
            </div>
            <button className="slider-button prev" onClick={goToPrevious}>
                &lt;
            </button>
            <button className="slider-button next" onClick={goToNext}>
                &gt;
            </button>
            <div className="slider-pages">
                {items && items.map((_, index) => (
                    <span
                        key={index}
                        className={`page-dot ${index === currentIndex ? 'active' : ''}`}
                        onClick={() => goToImage(index)}
                    ></span>
                ))}
            </div>
        </div>
    );
}

export default SliderComponent;