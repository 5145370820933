import React, {Component} from 'react';
import {bindActionCreators} from "redux";
import {updateCollection, updateNavigationParam, updateShop, updateUser} from "../../redux/systemSlice";
import {connect} from "react-redux";
import Utils, {withRouterHOC} from "../../utils";
import PreviewProduct from "../../app/components/previewProduct/previewProduct";

import './catalog.css';
import API from "../../app/api";

class CatalogShopProducts extends Component {
    constructor(props) {
        super(props);

        this.state = {
            openSubcategoriesFull: false,
            shopSlug: null
        }

        this.openNewCollection = (shopSlug) => {
            this.props.updateShop({products: []})
            API.shopEntityProducts(shopSlug)
                .then(data => {
                    Utils.setPageTitle(`${data.store.public_name} | ПабХаб`)

                    this.props.updateShop(data.store)
                })

            this.props.updateNavigationParam({
                page: 'catalog',
                catalogType: 'shop',
                shopSlug: shopSlug,
            })

            this.setState({
                shopSlug: shopSlug
            })
        }
    }

    componentDidMount() {
        this.openNewCollection(this.props.params.shopSlug)
    }

    render() {
        if (this.props.params.shopSlug !== this.state.shopSlug) {
            this.openNewCollection(this.props.params.shopSlug)
        }

        return (
            <div className='catalog-container'>
                <div className='catalog'>
                    <div className='name'>
                        {this.props.shop.public_name}
                    </div>
                    <div className='products'>
                        {
                            this.props.shop.products.map(product =>
                                <PreviewProduct product={product}/>
                            )
                        }
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = (state, ownProps) => ({
    categories: state.system.categories,
    collection: state.system.collection,
    shop: state.system.shop,
})

const mapDispatchToProps = (dispatch, ownProps) => {
    return bindActionCreators(
        {
            updateUser: updateUser,
            updateCollection: updateCollection,
            updateNavigationParam: updateNavigationParam,
            updateShop: updateShop,
        },
        dispatch
    );
}


export default connect(mapStateToProps, mapDispatchToProps)(withRouterHOC(CatalogShopProducts));