import React, {useState} from "react";
import {SERVER_URL} from "../../../config";
import ScoreComponent from "../scoreComponent/scoreComponent";

import {ReactComponent as GoToRightArrowMiniBlackIcon} from "../../../images/goToRightArrowMiniBlack.svg";

import './review.css';
import {Link} from "react-router-dom";

function formatDate(date) {
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  return `${day}.${month}.${year}, ${hours}:${minutes}`;
}

function Review({review}) {
    const [isGandonOpen, setGandonOpen] = useState(false);

    return <div className='review-component'>
        <Link className='user' to={`/user/${review.user.username}/`}>
            <div className='avatar'>
                <img src={review.user.avatar_url ?
                    review.user.avatar_url :
                    'https://media.pubhub.su/assets/images/avatar-default.png'} alt='avatar'/>
            </div>
            <div className='content'>
                <div className='name'>{review.user.name}</div>
                <div
                    className='date'>{review.created_at ? formatDate(new Date(review.created_at)) : null}</div>
            </div>
            <div className="score">
                <ScoreComponent rating={review.rating} isBigReviewScore={true}/>
            </div>
        </Link>
        {
            review.product ?
                <Link className="product-entry" to={`/product/${review.product.slug}/`}>
                    <div className='image'>
                        <img src={review.product.image_url ? review.product.image_url : 'https://media.pubhub.su/assets/images/beer-default.png'} alt='product'/>
                    </div>
                    <div className="content">
                        <div className='name'>{review.product.name}</div>
                        <ScoreComponent rating={review.product.average_rating} isMiniReviewScore={true}/>
                    </div>
                    <div className='arrow'>
                        <GoToRightArrowMiniBlackIcon/>
                    </div>
                </Link> : null
        }
        <div className='comment'>
            {
                review.comment.length > 0 ? <div className='title'>Комментарий</div> : null
            }
            <div className='comment-text'>{review.comment.length > 240 ? (
                isGandonOpen ? review.comment : review.comment.slice(0, 240) + '...'
            ) : review.comment}</div>
            {
                review.comment.length > 240 && isGandonOpen === false ?
                    <div className='show-more' onClick={() => setGandonOpen(true)}>
                        Показать полностью
                    </div> : null
            }
        </div>
        {
            review.image_url ? <div className='photo'>
                <img src={review.image_url} alt='photo'/>
            </div> : null
        }

    </div>
}

export default Review;

