import React from "react";

import {ReactComponent as GoToRightArrowIcon} from '../../../images/goToRightArrow.svg';

import './previewCollection.css'
import {Link} from "react-router-dom";
import PreviewProduct from "../previewProduct/previewProduct";

const PreviewCollection = ({collection, catalogType, limit, addPath, collectionName, productsCount, customUrl}) => {
    return (
        <div key={collection.name} className='preview-collection'>
            <Link className='title'
                  to={customUrl ? customUrl : `/catalog/${catalogType}/${collection.slug}/${addPath ? addPath : ''}`}>
                {collectionName ? collectionName : collection.name}
                {
                    productsCount ? <span>{productsCount}</span> : null
                }
                <GoToRightArrowIcon/>
            </Link>
            <div className='products'>
                {
                    collection.products ? collection.products.slice(0, limit ? limit : 5).map(product => {
                        return <PreviewProduct key={product.name} product={product}/>
                    }) : null
                }
            </div>
        </div>
    );
};


export default PreviewCollection;