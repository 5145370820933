import React from "react";
import {ReactComponent as BeerColorLightIcon} from '../../../images/beerColors/beerColorLight.svg';

import './previewProduct.css'
import {Link} from "react-router-dom";
import {SERVER_URL} from "../../../config";
import ScoreComponent from "../scoreComponent/scoreComponent";

const PreviewProduct = ({product}) => {
    return (
        <Link key={product.name} className="preview-product" to={`/product/${product.slug}/`}>
            <div className='product'>
                <div className='image'>
                    <img src={
                        product.image_url ?
                            product.image_url :
                            'https://media.pubhub.su/assets/images/beer-default.png'
                    } alt={product.name}/>
                </div>
                {
                    product.partner_price || product.from_price ?
                        <div className='price-container'>
                            {
                                product.partner_price ?
                                    <div className='price'>{product.partner_price} ₽</div> :
                                    <div className='price'>от {product.from_price} ₽</div>
                            }
                            <div className='size'>за {product.volume ? product.volume : 1} л</div>
                        </div> :
                        <div className='price-container'>
                            <div className='price gray'>Нет в продаже</div>
                        </div>
                }
                <ScoreComponent rating={product.average_rating} reviews={product.review_count}/>
                <div className='name'>
                    {product.name}
                </div>
                <div className='brewery'>
                    {product.brewery_label}
                </div>
                <div className='bottom-style'>
                    <div className='style'>
                        {/*<BeerColorLightIcon/>*/}
                        {product.full_style_label}
                    </div>
                </div>
                <div className='bottom'>
                    {product.alcohol_degrees ? <div className='alcohol'>
                        {product.alcohol_degrees}%
                        <span>алк</span>
                    </div> : null}

                    {product.bitterness_scale ? <div className='bitterness'>
                        {product.bitterness_scale}
                        <span>IBU</span>
                    </div> : null}
                </div>
            </div>
        </Link>
    );
};


export default PreviewProduct;