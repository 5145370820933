import React, {useState} from "react";
import SetRatingComponent from "../setRating/setRating";

import './newReview.css';
import {Fingerprint} from "../../../utils/storage";
import {ReactComponent as ReplaceIcon} from "../../../images/replace.svg";
import {ReactComponent as ClearIcon} from "../../../images/clear.svg";
import {bindActionCreators} from "redux";
import {updateNavigationParam, updatePartnerUser, updateProduct, updateUser} from "../../../redux/systemSlice";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import API from "../../api";


function NewReviewComponent({product, uploadHandler, user}) {
    const [internalId, setInternalId] = useState(Fingerprint.hashFunc(String(Math.random())))
    const [selectedRating, setSelectedRating] = useState(null);
    const [imageBase64, setImageBase64] = useState(null);
    const [comment, setComment] = useState(null);

    const addReview = () => {
        API.addReview(product.id, comment, imageBase64, selectedRating)
            .then(data => {
                window.location.reload();
            })
    }

    const fileInputChange = (event) => {
        const target = event.target;
        if (target.files && target.files[0]) {
            const file = target.files[0];
            const reader = new FileReader();
            reader.onload = (event) => {
                let base64String = event.target.result;
                if (uploadHandler) uploadHandler(base64String);
                setImageBase64(base64String)
            };
            reader.readAsDataURL(file);
        }
    };

    const buttonClass = ['button-photo']
    if (imageBase64) buttonClass.push('button-phone-hidden')

    return <div className='new-review-component'>
        <div className='header'>
            <div className='title'>Уже попробовали? Поставьте оценку</div>
            <div className='stars'>
                <SetRatingComponent callback={setSelectedRating}/>
            </div>
        </div>
        {
            selectedRating !== null ?
                user.authorize ?
                    <>
                        <div className='body'>
                            <div className='title'>Вы можете оставить комментарий</div>
                            <div className='input'>
                                <input
                                    value={comment}
                                    onInput={(event) => setComment(event.target.value)}
                                />
                            </div>
                        </div>
                        <div className='buttons'>
                            {
                                imageBase64 ? (
                                    <div className='image-container'>
                                        <img alt='preview' src={imageBase64 ? imageBase64 : null}/>
                                        <div className="ImageUploader-preview-buttons">
                                            <div className="ImageUploader-preview-button"
                                                 onClick={() => {
                                                     document.getElementById(`image-${internalId}`).click()
                                                 }}>
                                                <ReplaceIcon/>
                                                Заменить
                                            </div>
                                            <div className="ImageUploader-preview-button"
                                                 onClick={() => setImageBase64(null)}>
                                                <ClearIcon/>
                                                Очистить
                                            </div>
                                        </div>
                                    </div>
                                ) : null
                            }
                            <div className={buttonClass.join(' ')}
                                 onClick={() => {
                                     document.getElementById(`image-${internalId}`).click()
                                 }}>
                                Прикрепить фото
                                <input
                                    className='button-hidden-input'
                                    id={`image-${internalId}`}
                                    name='image'
                                    type='file'
                                    accept='image/*'
                                    onChange={(event) => fileInputChange(event)}
                                />
                            </div>
                            <div className='button-add' onClick={() => addReview()}>
                                Добавить отзыв
                            </div>
                        </div>
                    </> :
                    <>
                        <div className='buttons'>
                            <Link className='button-add' to='/login'>
                                Войти в аккаунт
                            </Link>
                        </div>
                    </>
                : null
        }
    </div>
}

const mapStateToProps = (state, ownProps) => ({
    user: state.system.user,
    product: state.system.product,
    partnerUser: state.system.partnerUser,
})

const mapDispatchToProps = (dispatch, ownProps) => {
    return bindActionCreators(
        {
            updateUser: updateUser,
            updatePartnerUser: updatePartnerUser,
            updateProduct: updateProduct,
            updateNavigationParam: updateNavigationParam
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(NewReviewComponent);