import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {withRouterHOC} from "../../../utils";
import FadeComponent from "../animations/animations";

import {ReactComponent as CloseCrossIcon} from "../../../images/closeCross.svg";
import {updateNavigationParam, updatePartnerUser, updateUser} from "../../../redux/systemSlice";
import {withCookies} from "react-cookie";
import Storage from "../../../utils/storage";


import './agreeAccept.css';
import ModalComponent from "../modalComponent/modalComponent";


class AgreeAccept extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ageAccept: Storage.get('ageAccept'),
            cookiesAccept: Storage.get('cookiesAccept')
        }

        this.setCookiesAgree = () => {
            Storage.set('cookiesAccept', true)
            this.setState({cookiesAccept: true})
        }

        this.setAgeAgree = () => {
            Storage.set('ageAccept', true)
            this.setState({ageAccept: true})
        }

        this.blockAgeAgree = () => {
            window.location.href = 'https://ya.ru/search/?text=%D0%BC%D1%83%D0%BB%D1%8C%D1%82%D0%B8%D0%BA%D0%B8'
        }
    }

    render() {
        return (
            <div className='agree-accept-component'>
                <div className='cookies-container'>
                    <FadeComponent isVisible={!this.state.cookiesAccept}>
                        <div className='cookies-agree'>
                            <div className='header'>
                                <div className='title'>Мы используем куки</div>
                                <CloseCrossIcon onClick={this.setCookiesAgree}/>
                            </div>
                            <div className='content'>
                                <a href='/documents/privacy/' target='_blank'>
                                    Политика конфиденциальности
                                </a> расскаызвает, каким образом и зачем
                            </div>
                            <div className='button' onClick={this.setCookiesAgree}>
                                Замечательно
                            </div>
                        </div>
                    </FadeComponent>
                </div>
                <div className='age-container'>
                    <ModalComponent isOpen={!this.state.ageAccept} isBackgroundBlur={true} isCloseButtonHidden={true}>
                        <div className='age-agree'>
                            <div className='header'>
                                <div className='title'>Вам уже исполнилось 18 лет?</div>
                            </div>
                            <div className='content'>
                                Данная страница содержит информацию об алкогольной продукции, — пожалуйста, подтвердите
                                своё совершеннолетие для продолжения
                            </div>
                            <div className='buttons'>
                                <div className='button blue' onClick={this.setAgeAgree}>
                                    Да, исполнилось
                                </div>
                                <div className='button' onClick={this.blockAgeAgree}>
                                    Нет
                                </div>
                            </div>
                        </div>
                    </ModalComponent>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.system.user,
        navigation: state.system.navigation,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            updateUser: updateUser,
            updatePartnerUser: updatePartnerUser,
            updateNavigationParam: updateNavigationParam
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouterHOC(withCookies(AgreeAccept)));