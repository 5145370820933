import React, {Component, useState} from 'react';
import {bindActionCreators} from "redux";
import {updateCategories, updateMainPage, updateNavigationParam, updateUser} from "../../redux/systemSlice";
import {connect} from "react-redux";

import PreviewCollection from "../../app/components/previewCollection/previewCollection";
import SliderComponent from "../../app/components/sliderComponent/sliderComponent";
import BreweriesImage from "../../images/catalog/breweries.png";
import ColorsImage from "../../images/catalog/colors.png";
import StyleImage from "../../images/catalog/styles.png";
import FilterImage from "../../images/catalog/filters.png";
import CountryImage from "../../images/catalog/countries.png";
import {Link} from "react-router-dom";


import {ReactComponent as CatalogIcon} from "../../images/catalog.svg";
import {ReactComponent as CatalogAllIcon} from "../../images/catalog-all.svg";

import './home.css';
import Utils from "../../utils";


const CATEGORY_IMAGE = {
    brewery: BreweriesImage,
    color: ColorsImage,
    style: StyleImage,
    filter: FilterImage,
    country: CountryImage,
}


const CatalogNavigator = ({categories}) => {
    const [currentTab, setCurrentTab] = useState('brewery');
    const [currentTabIndex, setCurrentTabIndex] = useState(0);

    return <div className='catalog-navigator'>
        <div className='title'>Пивной навигатор</div>
        <div className='labels'>
            {
                categories.map((category, index) => {
                    let categoryClass = ['label']
                    if (category.slug === currentTab) categoryClass.push('active')
                    return <div key={category.slug} className={categoryClass.join(' ')}
                                onClick={() => {
                                    setCurrentTab(category.slug);
                                    setCurrentTabIndex(index)
                                }}>
                        <img src={CATEGORY_IMAGE[category.slug]} alt=''/>
                        {category.name}
                    </div>
                })
            }
            <Link className='label catalog-link' to='/catalog'>
                <div className='avatar'>
                    <CatalogIcon/>
                </div>
                Перейти в каталог
            </Link>
        </div>
        {
            categories.length > 0 && categories[currentTabIndex]['collections'] && categories[currentTabIndex]['collections'].length ?
                <div className='collections'>
                    {
                        categories.length ? categories[currentTabIndex]['collections'].map((collection, index) => {
                            return <Link className='collection'
                                         to={`/catalog/${collection['category']}/${collection['slug']}`}>
                                <div className='image'>
                                    <img alt=''
                                         src={collection.product.image_url ? collection.product.image_url : 'https://media.pubhub.su/assets/images/beer-default.png'}/>
                                </div>
                                <div className='name'>
                                    {collection.name}
                                </div>
                            </Link>
                        }) : null
                    }
                    <Link className='collection for-text' to={`/catalog/${currentTab}`}>
                        <div className='text-container'>
                            <div className='text'>Смотреть все подборки</div>
                            <CatalogAllIcon/>
                        </div>
                    </Link>
                </div> :
                <div className='not-found-label'>Скоро тут будут подборки...</div>
        }
    </div>
}


const MainBanner = ({banner}) => {
    return <a className='main-banner' href={banner.href} target='_blank'>
        <div className='image'>
            <img src={banner.image} alt=''/>
        </div>
        <div className='content'>
            <div className='before'>{banner.before}</div>
            <div className='title'>{banner.title}</div>
            <div className='tags'>
                {banner.tags.map(tag => {
                    return <div className='tag'>{tag}</div>
                })}
            </div>
        </div>
    </a>
}

class Home extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.updateNavigationParam({
            page: 'home',
        })

        this.props.API.mainPage()
            .then(data => {
                this.props.updateMainPage(data.data)
            })

        this.props.API.categories(true)
            .then(data => {
                this.props.updateCategories(data.categories)
            })

        Utils.setPageTitle(`Главная | ПабХаб`)
    }

    render() {
        return (
            <div className='catalog-container'>
                <div className='catalog-main-slider'>
                    <SliderComponent items={this.props.mainPage.slider ? this.props.mainPage.slider : []}/>
                    <div className='catalog-main-quote'>
                        <div className='quote'>
                            Пусть каждый глоток будет открытием! Исследуйте вкусы, делитесь впечатлениями и становитесь
                            частью комьюнити, которое живёт пивной культурой. Ваше увлечение – наша гордость!
                        </div>
                        <div className='avatar-container'>
                            <div className='avatar'>
                                <img alt=''
                                     src='https://sun9-3.userapi.com/impg/DpvoKt4CiuMyc_OkMTOB_OheJgazywx8u_5S5g/hQCnfBcMAGA.jpg?size=96x96&quality=95&sign=74cb9932c24fc6e660704ccbb12a5584&type=album'/>
                            </div>
                            <div className='content'>
                                <div className='title'>Команда ПабХаб</div>
                                <div className='description'>Показываем пиво</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='catalog'>
                    <CatalogNavigator categories={this.props.categories}/>
                </div>
                <div className='catalog'>
                    <div className='collections'>
                        {
                            this.props.mainPage.collections ? this.props.mainPage.collections.map((collection, index) => {
                                    const banner = this.props.mainPage.banners[index]
                                    return <>
                                        {
                                            banner ? <MainBanner banner={banner}/> : null
                                        }
                                        <PreviewCollection
                                            key={collection.name}
                                            collection={collection}
                                            catalogType={collection['catalogType']}
                                        />
                                    </>
                                }
                            ) : null
                        }
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.system.user,
        mainPage: state.system.mainPage,
        categories: state.system.categories,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            updateCategories: updateCategories,
            updateUser: updateUser,
            updateMainPage: updateMainPage,
            updateNavigationParam: updateNavigationParam,
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
