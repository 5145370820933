import {createSlice} from '@reduxjs/toolkit';



export const systemInterface = createSlice({
    name: 'system',
    initialState: {
        user: {},
        partnerUser: {},
        profile: {
            reviews: []
        },
        navigation: {},
        categories: [],
        category: {
            collections: []
        },
        collection: {
            products: []
        },
        product: {
            shops: [],
            stores: [],
            reviews: []
        },
        shop: {
            products: []
        },
        mainPage: {}
    },
    reducers: {
        updateUser: (state, action) => {
            // console.log(action)
            state.user = action.payload
            // console.log(state.user.kek)
        },
        updatePartnerUser: (state, action) => {
            // console.log(action)
            state.partnerUser = action.payload
            // console.log(state.user.kek)
        },
        updateProfile: (state, action) => {
            // console.log(action)
            state.profile = action.payload
            // console.log(state.user.kek)
        },
        updateMainPage: (state, action) => {
            state.mainPage = action.payload
            // console.log(state.user.kek)
        },
        updateCategory: (state, action) => {
            state.category = action.payload
            // console.log(state.user.kek)
        },
        updateCollection: (state, action) => {
            state.collection = action.payload
            // console.log(state.user.kek)
        },
        updateShop: (state, action) => {
            state.shop = action.payload
            // console.log(state.user.kek)
        },
        updateCategories: (state, action) => {
            state.categories = action.payload
            // console.log(state.user.kek)
        },
        updateNavigation: (state, action) => {
            // console.log(action)
            state.navigation = action.payload
            // console.log(state.navigation)
        },
        updateProduct: (state, action) => {
            // console.log(action)
            state.product = action.payload
            // console.log(state.navigation)
        },
        updateNavigationParam: (state, action) => {
            // console.log(action)
            state.navigation = Object.assign({}, action.payload)
            // console.log(state.navigation)
        },
        increment: (state) => {
            state.value += 1;
        },
        decrement: (state) => {
            state.value -= 1;
        },
    },
});

export const {
    updateUser,
    updatePartnerUser,
    updateNavigation,
    updateNavigationParam,
    updateCategories,
    updateCategory,
    updateCollection,
    updateProduct,
    updateShop,
    updateProfile,
    updateMainPage
} = systemInterface.actions;
export default systemInterface.reducer;