import React from "react";
import {useLocation, useNavigate, useParams, useSearchParams} from "react-router-dom";

export {default as storage} from './storage'

// export function convertDates(keys) {
//     return function (obj) {
//         keys.forEach((key) => {
//             obj[key] = obj[key] && new Date(obj[key]) || null
//         })
//         return obj
//     }
// }

function fallbackCopyTextToClipboard(text) {
    var textArea = document.createElement("textarea");
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
        var successful = document.execCommand('copy');
        var msg = successful ? 'successful' : 'unsuccessful';
        console.log('Fallback: Copying text command was ' + msg);
    } catch (err) {
        console.error('Fallback: Oops, unable to copy', err);
    }

    document.body.removeChild(textArea);
}

export function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export function getTs() {
    return (new Date()).getTime()
}

export function timePassed(flag) {
    return (getTs() - flag) / 1000
}

export function copyTextToClipboard(text) {
    if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(text);
        return;
    }
    navigator.clipboard.writeText(text).then(function () {
        console.log('Async: Copying to clipboard was successful!');
    }, function (err) {
        console.error('Async: Could not copy text: ', err);
    });
}

export function generateTaxiUrl(coords) {
    try {
        let parsedCoords = coords.split(',').map(coord => parseFloat(coord))
        return `https://3.redirect.appmetrica.yandex.com/route?end-lat=${parsedCoords[0]}6&end-lon=${parsedCoords[1]}&tariffClass=econom&ref=pubhubsu&appmetrica_tracking_id=1178268795219780156`
    } catch (e) {
        return ''
    }
}

class Utils {
    static setPageTitle(title) {
        document.title = `${title}`
    }

    static titleString(string) {
        let result = string.charAt(0).toUpperCase() + string.slice(1)
        return result.replace('_', ' ');
    }

    // static isDeviceMobile() {
    //     let check = false;
    //     (function (a) {
    //         if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) check = true;
    //     })(navigator.userAgent || navigator.vendor || window.opera);
    //     return check;
    // }

    static condition(conditionExp, elementsTrue, elementFalse) {
        let elements = elementsTrue.map(element => element)
        return conditionExp ? <>{elements}</> : elementFalse
    }

    static listToDict(array, key) {
        let newDict = {}
        array.forEach(item => {
            newDict[item[key]] = item
        })
        return newDict
    }

    static arrayHas(array, key) {
        return array.indexOf(key) >= 0
    }
}

function rangeList(n) {
    return Array(n).fill(0).map((_, i) => i)
}

function descendingComparator(a, b, orderBy) {
    if (orderBy === 'craneNumber') {
        if (a[orderBy] === undefined) {
            return -9999;
        }
        if (b[orderBy] === undefined) {
            return 9999;
        }
    }
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    if (orderBy === 'gpt_score') orderBy = 'gpt_score_compare'

    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const safetyInput = (value) => {
    return ((value !== null) && (typeof value !== 'undefined')) ? value : ""
}

const safetyName = (user) => {
    return `${user.first_name ? user.first_name : ''} ${user.last_name ? user.last_name : ''}`
}

const safetyExternalUrl = (url) => {
    if (url) {
        return url.startsWith('http://') || url.startsWith('https://')
            ? url
            : `https://${url}`;
    }
}

const withRouterHOC = (WrappedComponent) => (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    const [searchParams, setSearchParams] = useSearchParams();

    return <WrappedComponent
        {...props}
        location={location}
        navigate={navigate}
        params={params}
        searchParams={searchParams}
        setSearchParams={setSearchParams}
    />;
};

export {
    safetyInput,
    safetyName,
    safetyExternalUrl,
    descendingComparator,
    getComparator,
    stableSort,
    rangeList,
    withRouterHOC
};
export default Utils