import React, {Component} from 'react';


import './setRating.css'
import {rangeList} from "../../../utils";

const LABELS = {
    0: 'Казалось, что хуже некуда..',
    1: 'Хуже некуда',
    2: 'Еле выдержал',
    3: 'Такое себе',
    4: 'Слабовато',
    5: 'На разок',
    6: 'Бывает и лучше',
    7: 'Вполне достойно',
    8: 'Прекрасно',
    9: 'Просто огонь',
    10: 'Пивной шедевр',
}

class SetRatingComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isHover: false,
            chooseRating: 'Не выбрано',
            rating: null,
        }

        this.setRating = (rating) => {
            const newRating = rating === this.state.chooseRating ? null : rating
            this.setState({chooseRating: newRating}, () => {
                if (this.props.callback) this.props.callback(newRating)
            })
        }
    }

    render() {
        let labelClass = ['label']
        if (this.state.chooseRating !== null) {
            if (this.state.chooseRating <= 0) labelClass.push('kal')
            else if (this.state.chooseRating <= 4) labelClass.push('bad')
            else if (this.state.chooseRating >= 5 && this.state.chooseRating <= 7) labelClass.push('middle')
            else labelClass.push('good')
        }

        return <div className='set-rating-container'>
            <div className='rating-line'>
                {
                    rangeList(11).map(index => {
                        let elementClass = ['rating-line-element']

                        if (index <= 0) elementClass.push('kal')
                        else if (index <= 4) elementClass.push('bad')
                        else if (index >= 5 && index <= 7) elementClass.push('middle')
                        else elementClass.push('good')
                        if (this.state.chooseRating === index) elementClass.push('active')
                        return <div key={index} className={elementClass.join(' ')}
                                    onClick={() => this.setRating(index)}
                                    onMouseEnter={() => this.setState({rating: index, isHover: true})}
                                    onMouseLeave={() => this.setState({rating: null, isHover: false})}
                        >
                            {index}
                        </div>
                    })
                }
            </div>
            {
                this.state.chooseRating !== null ? <div className={labelClass.join(' ')}>
                    {LABELS[this.state.chooseRating]}
                </div> : null
            }
        </div>
    }
}


export default SetRatingComponent;