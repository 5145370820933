import React, {Component} from 'react';
import {bindActionCreators} from "redux";
import {updateNavigationParam, updateProduct, updateUser} from "../../redux/systemSlice";
import {connect} from "react-redux";
import Utils, {generateTaxiUrl, withRouterHOC} from "../../utils";
import {Link} from "react-router-dom";
import {ReactComponent as GoToRightArrowMiniIcon} from "../../images/goToRightArrowMini.svg";
import {ReactComponent as GoToRightArrowMiniBlackIcon} from "../../images/goToRightArrowMiniBlack.svg";
import {ReactComponent as GoToDownArrowMiniBlackIcon} from "../../images/goToDownArrowMiniBlack.svg";
import {ReactComponent as GoToRightArrowIcon} from '../../images/goToRightArrow.svg';
import {ReactComponent as OnMapIcon} from '../../images/onMap.svg';
import mapDefaultImage from "../../images/mapDefaultImage.jpg";

import API from "../../app/api";
import {SERVER_URL} from "../../config";
import {Map, Placemark, YMaps} from "@pbe/react-yandex-maps";
import ScoreComponent from "../../app/components/scoreComponent/scoreComponent";
import NewReviewComponent from "../../app/components/newReview/newReview";

import './product.css'
import Review from "../../app/components/review/review";
import ModalComponent from "../../app/components/modalComponent/modalComponent";
import TaxiIcon from "../../images/taxiIcon.png";


function productStoresLabel(length) {
    if (length === 1) return 'заведение'
    if (length >= 2 && length <= 4) return 'заведения'
    return 'заведений'
}

function MapRender({product, isOpenMap, openMap, chooseStore, activeMapStore, centerMap}) {
    if (product.stores && product.stores.length) {
        let mapClass = ['map']
        if (isOpenMap) mapClass.push('openedMap')

        return <div className='map-container' id='map'>
            <div className='title-container'>
                <div className='title'>Где купить</div>
                <div className='description'>{product.name}</div>
            </div>
            <div className={mapClass.join(' ')}>
                {
                    isOpenMap ? <YMaps query={{
                        apikey: '30a9608c-2a37-4c11-b6b9-94b01f495b5f'
                    }}>
                        <div className='map-entry-container'>
                            <Map
                                defaultState={{
                                    center: centerMap, zoom: 10
                                }}
                                modules={["control.ZoomControl", "control.FullscreenControl"]}
                                width='100%' height='400px'>
                                {
                                    product.stores.map(store => {
                                        if (store.public_coords) {
                                            try {
                                                let coords = store.public_coords.split(',').map(coord => parseFloat(coord))
                                                return <Placemark

                                                    defaultGeometry={coords}
                                                    options={{
                                                        preset: 'islands#redDotIcon'
                                                    }}
                                                    properties={{
                                                        iconContent: store.public_name
                                                    }}
                                                    onClick={(e) => chooseStore(store)}
                                                />
                                            } catch (e) {
                                                return null
                                            }
                                        }
                                    })
                                }
                            </Map>
                        </div>
                    </YMaps> : <>
                        <div className='button-container' onClick={openMap}>
                            <div className='button'>
                                Показать {product.stores.length} {productStoresLabel(product.stores.length)}
                            </div>
                        </div>
                        <div className='blur'/>
                        <img
                            src={mapDefaultImage}
                            alt='map'/>
                    </>
                }
            </div>
            {
                activeMapStore ?
                    <div className='active-map-store'>
                        <div className='top-container'>
                            {
                                activeMapStore.public_image_url ?
                                    <div className='avatar'>
                                        <img src={activeMapStore.public_image_url} alt=''/>
                                    </div> : null
                            }
                            <div className='top'>
                                <Link className='name' to={activeMapStore.slug ? `/shop/${activeMapStore.slug}` : null}>
                                    {activeMapStore.public_name}
                                    <GoToRightArrowIcon/>
                                </Link>
                                <div className='address'>{activeMapStore.public_address}</div>
                                {
                                    activeMapStore.public_coords ?
                                        <a className='button taxi'
                                           href={generateTaxiUrl(activeMapStore.public_coords)}
                                           target='_blank'>
                                            <img src={TaxiIcon} alt='taxi'/>
                                            Вызвать такси
                                        </a>
                                        : null
                                }
                            </div>
                        </div>
                        {/*<div className='buttons'>*/}
                        {/*    /!*{activeMapStore.public_website ?*!/*/}
                        {/*    /!*    <a className="button" href={safetyExternalUrl(activeMapStore.public_website)}*!/*/}
                        {/*    /!*       target='_blank'>*!/*/}
                        {/*    /!*        Перейти на сайт*!/*/}
                        {/*    /!*    </a> : null*!/*/}
                        {/*    /!*}*!/*/}
                        {/*</div>*/}
                    </div>
                    : null
            }
        </div>
    }
    return <div className='map-container'>
        <div className='title-container'>
            <div className='title'>На карте тоже не покажем :(</div>
        </div>
        <div className='map'>
            <div className='button-container disabled'>
                <div className='button'>Показать заведения</div>
            </div>
            <div className='blur'/>
            <img src={mapDefaultImage} alt='map'/>
        </div>
    </div>
}


class Product extends Component {
    constructor(props) {
        super(props);

        this.state = {
            category: {
                collections: []
            },
            collection: {
                products: []
            },
            product: {
                review_images: [],
                shops: [],
                reviews: []
            },

            isOpenMap: false,
            activeMapStore: null,
            centerMap: [55.75, 37.57],
            onShopHover: null,

            isModalShopsOpen: false
        }

        this.setModalShopsStatus = (status) => {
            this.setState({isModalShopsOpen: status})
        }

        this.openMap = () => {
            this.setState({isOpenMap: true})
        }

        this.chooseStore = (store) => {
            this.setState({activeMapStore: store})
        }

        this.setShopHover = (shopId) => {
            this.setState({onShopHover: shopId})
        }
    }

    componentDidMount() {
        let productSlug = this.props.params.productSlug;

        this.props.updateProduct({products: []})
        API.productEntity(productSlug)
            .then(data => {
                this.props.updateProduct(data.product)

                Utils.setPageTitle(`${data.product.name} | ПабХаб`)

                let xCoord = 0;
                let yCoord = 0;
                data.product.stores.map(store => {
                    let coords = store.public_coords.split(',').map(coord => parseFloat(coord))
                    xCoord += coords[0]
                    yCoord += coords[1]
                })
                const middleCoords = [xCoord / data.product.stores.length, yCoord / data.product.stores.length]
                this.setState({centerMap: middleCoords})
            })

        this.props.updateNavigationParam({
            page: 'product',
            productSlug: this.props.params.productSlug,
        })

        window.scrollTo({top: 0})
    }

    render() {
        // console.log(this.state.product)

        const product = this.props.product

        return (
            <div className='product-container'>
                <div className='product'>
                    <div className='header'>
                        <div className='breadcrumbs'>
                            <div className='item'>
                                <Link to='/catalog'>Каталог</Link>
                            </div>
                            {
                                product.color ?
                                    <>
                                        <span>•</span>
                                        <div className='item'>
                                            <Link to={`/catalog/color/${product.color.slug}`}>
                                                {product.color.name}
                                            </Link>
                                        </div>
                                    </>
                                    : null
                            }
                            {
                                product.brewery ?
                                    <>
                                        <span>•</span>
                                        <div className='item'>
                                            <Link to={`/catalog/brewery/${product.brewery.slug}/`}>
                                                {product.brewery.name}
                                            </Link>
                                        </div>
                                    </>
                                    : null
                            }
                            <span>•</span>
                            <div className='item last-item'>
                                {product.name}
                            </div>
                        </div>
                        <div className='title'>
                            {product.name}
                        </div>
                        <ScoreComponent rating={product.average_rating} reviews={product.review_count}/>
                    </div>
                    <div className='content'>
                        <div className='image-container'>
                            <div className='avatar'>
                                <img
                                    src={product.image_url ? product.image_url : 'https://media.pubhub.su/assets/images/beer-default.png'}
                                    alt={product.name}/>
                            </div>
                            <div className='review-images-container'>
                                {product.review_images ? product.review_images.map((image, index) => {
                                    return <div key={index} className='review-image'>
                                        <img src={image} alt='review-image'/>
                                    </div>
                                }) : null}
                            </div>
                        </div>
                        <div className='right-container'>
                            <div className='info-container'>
                                {
                                    product.brewery ?
                                        <div className='info-block'>
                                            <div className='title'>Пивоварня</div>
                                            <div className='content'>
                                                {/*<div className='content-icon'>*/}
                                                {/*    <img src={product.brewery_image} alt='brewery'/>*/}
                                                {/*</div>*/}
                                                <Link to={`/catalog/brewery/${product.brewery.slug}/detail/`}
                                                      className='content-link'>
                                                    {product.brewery.name}
                                                    <GoToRightArrowMiniIcon/>
                                                </Link>
                                            </div>
                                        </div>
                                        : null
                                }
                                {
                                    product.country ?
                                        <div className='info-block'>
                                            <div className='title'>Страна</div>
                                            <div className='content'>
                                                {/*<div className='content-icon'>*/}
                                                {/*    <RussiaIcon/>*/}
                                                {/*</div>*/}
                                                <Link to={`/catalog/country/${product.country.slug}`}
                                                      className='content-link'>
                                                    {product.country.name}
                                                    <GoToRightArrowMiniIcon/>
                                                </Link>
                                            </div>
                                        </div>
                                        : null
                                }
                                {
                                    product.alcohol_degrees !== null || product.alcohol_density !== null || product.bitterness_scale !== null ?
                                        <div className='info-block'>
                                            <div className='title'>Характеристики</div>
                                            <div className='content'>
                                                {
                                                    product.alcohol_degrees === null ? null :
                                                        <div className='content-param'>
                                                            <div className='value'>{product.alcohol_degrees}%</div>
                                                            <div className='field'>алкоголь</div>
                                                        </div>
                                                }
                                                {
                                                    product.alcohol_density === null ? null :
                                                        <div className='content-param'>
                                                            <div className='value'>{product.alcohol_density}%</div>
                                                            <div className='field'>плотность</div>
                                                        </div>
                                                }
                                                {
                                                    product.bitterness_scale === null ? null :
                                                        <div className='content-param'>
                                                            <div className='value'>{product.bitterness_scale}</div>
                                                            <div className='field'>IBU</div>
                                                        </div>
                                                }
                                            </div>
                                        </div> : null
                                }
                                {
                                    product.color ?
                                        <div className='info-block'>
                                            <div className='title'>Цвет</div>
                                            <div className='content'>
                                                <Link to={`/catalog/color/${product.color.slug}`}
                                                      className='content-link'>
                                                    {/*<BeerColorLightIcon/>*/}
                                                    {product.color.name}
                                                </Link>
                                            </div>
                                        </div>
                                        : null
                                }
                                {
                                    product.styles ? <div className='info-block'>
                                            <div className='title'>Стиль</div>
                                            <div className='content'>
                                                <div className='content-link-array'>
                                                    {product.styles.map((item, index) => {
                                                        return <Link key={index} to={`/catalog/style/${item.slug}`}>
                                                            {item.name}
                                                            {product.styles.length - 1 !== index ? ',' : ''}
                                                        </Link>
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                        : null
                                }
                                {
                                    product.description ? <div className='info-block'>
                                        <div className='title'>Описание</div>
                                        <div className='content'>
                                            <div className='content-text-big'>
                                                {product.description}
                                            </div>
                                        </div>
                                    </div> : null
                                }
                            </div>
                            <div className='partners-container'>
                                {
                                    product.stores && product.stores.length ?
                                        <>
                                            <div className='price-container'>
                                                <div className='price'>от {product.from_price} ₽</div>
                                                <div className='size'>за {product.volume} л</div>
                                            </div>
                                            <div className='shops-label'>Продаётся в {product.stores.length} магазинах
                                            </div>
                                            <div className='shops'>
                                                {product.stores.length ? product.stores.slice(0, 5).map((shop, index) => {
                                                    const isShopHover = this.state.onShopHover === index

                                                    const shopVolume = shop.volume ? shop.volume : 1

                                                    return <Link key={shop.name} className='shop'
                                                                 to={shop.slug ? `/shop/${shop.slug}` : null}
                                                                 onMouseEnter={() => this.setShopHover(index)}
                                                                 onMouseLeave={() => this.setShopHover(null)}
                                                    >
                                                        <div className='avatar'>
                                                            <img
                                                                src={shop.public_image_url ? shop.public_image_url : 'https://media.pubhub.su/assets/images/shop-default.png'}
                                                                alt='shop'/>
                                                        </div>
                                                        <div className='body'>
                                                            {
                                                                isShopHover ? <div className='top'>
                                                                    {shop.price} ₽
                                                                    <span>{shopVolume} л</span>
                                                                </div> : (
                                                                    shopVolume === product.volume ?
                                                                        <div className='top'>
                                                                            {shop.price} ₽
                                                                            <span>{shopVolume} л</span>
                                                                        </div> :
                                                                        <div className='top'>
                                                                            ~{Math.round(shop.price * (product.volume / shopVolume))} ₽
                                                                            <span>{product.volume} л</span>
                                                                        </div>
                                                                )
                                                            }

                                                            <div className='bottom'>
                                                                <div className='score-container'>
                                                                    <div className='name'>{shop.public_name}</div>
                                                                    {/*<div className='score'>*/}
                                                                    {/*    <StarBlueIcon/>*/}
                                                                    {/*    {product.score}*/}
                                                                    {/*</div>*/}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='icon'>
                                                            <GoToRightArrowMiniBlackIcon/>
                                                        </div>
                                                    </Link>
                                                }) : null}
                                                {
                                                    (product.stores.length - 5) >= 1 ?
                                                        <div className='button-shops-container'>
                                                            <div className='body'
                                                                 onClick={() => this.setModalShopsStatus(true)}>
                                                                <div className='top'>Смотреть все магазины</div>
                                                                <div className='bottom'>Не показали
                                                                    ещё {product.stores.length - 5}</div>
                                                            </div>

                                                            <div className='icon'>
                                                                <GoToRightArrowMiniBlackIcon/>
                                                            </div>
                                                        </div>
                                                        : null
                                                }
                                                <a className='button-shops-map' href='#map'>
                                                    <div className='map'>
                                                        <OnMapIcon/>
                                                    </div>
                                                    <div className='label'>На карте</div>
                                                    <div className='icon'>
                                                        <GoToDownArrowMiniBlackIcon/>
                                                    </div>
                                                </a>
                                            </div>
                                        </> :
                                        <>
                                            <div className='price-container'>
                                                <div className='price'>Нет в продаже</div>
                                            </div>
                                            <div className='shops-label'>В магазинах партнёров</div>
                                        </>
                                }
                            </div>
                        </div>
                    </div>
                    <div className='bottom'>
                        <div className='reviews'>
                            <NewReviewComponent product={product}/>
                            {product.reviews ? product.reviews.map((review, index) => {
                                return <Review key={index} review={review}/>
                            }) : null}
                        </div>
                        <MapRender
                            product={product}
                            isOpenMap={this.state.isOpenMap}
                            openMap={this.openMap}
                            chooseStore={this.chooseStore}
                            activeMapStore={this.state.activeMapStore}
                            centerMap={this.state.centerMap}
                        />
                    </div>
                </div>
                <ModalComponent isOpen={this.state.isModalShopsOpen}
                                onClose={() => this.setModalShopsStatus(false)}>
                    <div className='product-shops-modal'>
                        <div className='label'>Продаётся в {product.stores && product.stores.length} магазинах</div>
                        <div className='shops'>
                            {product.stores && product.stores.length ? product.stores.map((shop, index) => {
                                const isShopHover = this.state.onShopHover === index

                                const shopVolume = shop.volume ? shop.volume : 1

                                return <Link key={shop.name} className='shop'
                                             to={shop.slug ? `/shop/${shop.slug}` : null}
                                >
                                    <div className='avatar'>
                                        <img
                                            src={shop.public_image_url ? shop.public_image_url : 'https://media.pubhub.su/assets/images/shop-default.png'}
                                            alt='shop'/>
                                    </div>
                                    <div className='body'>
                                        <div className='top'>
                                            {shop.price} ₽
                                            <span>{shopVolume} л</span>
                                        </div>

                                        <div className='bottom'>
                                            <div className='score-container'>
                                                <div className='name'>{shop.public_name}</div>
                                            </div>
                                        </div>
                                        <div className='bottom'>
                                            <div className='score-container'>
                                                <div className='address'>{shop.public_address}</div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className='icon'>
                                        <GoToRightArrowMiniBlackIcon/>
                                    </div>
                                </Link>
                            }) : null}
                        </div>
                    </div>
                </ModalComponent>
            </div>
        );
    }
}


const mapStateToProps = (state, ownProps) => ({
    product: state.system.product,
    categories: state.system.categories
})

const mapDispatchToProps = (dispatch, ownProps) => {
    return bindActionCreators(
        {
            updateUser: updateUser,
            updateProduct: updateProduct,
            updateNavigationParam: updateNavigationParam,
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouterHOC(Product));