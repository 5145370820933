import React, {Component} from 'react';
import {bindActionCreators} from "redux";
import {updateCategory, updateNavigationParam, updateUser} from "../../redux/systemSlice";
import {connect} from "react-redux";
import Utils, {withRouterHOC} from "../../utils";
import PreviewCollection from "../../app/components/previewCollection/previewCollection";

import './catalog.css';
import API from "../../app/api";

class CatalogCategory extends Component {
    constructor(props) {
        super(props);

        this.state = {
            page: 1,
            loadingPage: false,
            lastLoadedItems: 4,
            firstLoading: false
        }

        this.paginationNext = (catalogType) => {
            if (!this.state.loadingPage && this.state.lastLoadedItems >= 4) {
                this.setState({page: this.state.page + 1, loadingPage: true}, () => {
                    API.categoryItems(catalogType, this.state.page)
                        .then(data => {
                            let category = Object.assign({}, this.props.category)
                            let collections = this.props.category.collections.slice()
                            let newCollections = Array.from(collections)
                            data.collections.forEach(collection => {
                                newCollections.push(collection)
                            })
                            category.collections = newCollections
                            this.props.updateCategory(category)
                            this.setState({loadingPage: false, lastLoadedItems: data.collections.length})
                        })
                })
            }
        }

        this.handleProductsScroll = () => {
            if ((window.innerHeight + Math.round(window.scrollY)) >= document.body.offsetHeight) {
                this.paginationNext(this.props.params.catalogType)
                this.setState({showScrollUpButton: true})
            }
        }

        window.onscroll = this.handleProductsScroll
    }

    componentDidMount() {
        this.props.updateNavigationParam({
            page: 'catalog',
            catalogType: this.props.params.catalogType
        })

        this.props.updateCategory({collections: []})
        API.category(this.props.params.catalogType)
            .then(data => {
                Utils.setPageTitle(`${data.name} | ПабХаб`)

                this.props.updateCategory(data)
                this.setState({
                    firstLoading: true,
                    lastLoadedItems: data.collections.length
                })
            })
    }

    render() {
        return (
            <div className='catalog-container'>
                <div className='catalog'>
                    <div className='name'>{this.props.category.name}</div>
                    <div className='collections'>
                        {
                            this.props.category.collections.map(collection =>
                                <PreviewCollection
                                    key={collection.name}
                                    collection={collection}
                                    catalogType={this.props.params.catalogType}
                                />
                            )
                        }
                        {
                            this.state.firstLoading && this.props.category.collections.length === 0 ?
                                <div className='not-found-collections'>
                                    Скоро тут будут подборки...
                                </div>
                                : null
                        }
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = (state, ownProps) => ({
    categories: state.system.categories,
    category: state.system.category
})

const mapDispatchToProps = (dispatch, ownProps) => {
    return bindActionCreators(
        {
            updateUser: updateUser,
            updateCategory: updateCategory,
            updateNavigationParam: updateNavigationParam,
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouterHOC(CatalogCategory));