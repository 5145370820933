import React, {Component} from 'react';
import {bindActionCreators} from "redux";
import {updateNavigationParam, updatePartnerUser, updateUser} from "../../redux/systemSlice";
import {connect} from "react-redux";
import Utils, {withRouterHOC} from "../../utils";
import Storage from '../../utils/storage';
import './login.css';
import {Link} from "react-router-dom";
import API from "../../app/api";
import {withCookies} from "react-cookie";


class Forgot extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: null,
            password: null,

            activeTab: 'portal',
            tabs: {
                portal: 'от портала',
                partners: 'от партнёрского'
            },

            tokenPortal: null,
            tokenPartners: null,

            emailSent: false,
        }

        this.logout = () => {
            if (this.state.activeTab === 'portal') {
                this.props.cookies.remove('portalToken', {path: '/'})
                this.props.updateUser({authorize: false})
            } else {
                this.props.cookies.remove('token', {path: '/'})
                this.props.updatePartnerUser({authorize: false})
            }
        }

        this.resetPasswordStart = () => {
            API.resetPasswordStart(this.state.email, this.state.activeTab)
                .then(data => this.setState({emailSent: true}))
        }

        this.authorize = (callback) => {
            let email = this.state.email;
            let password = this.state.password
            API.csrf().then(data => {
                Storage.set('csrf', data.csrf_token)

                if (this.state.activeTab === 'portal') {
                    API.login(email, password)
                        .then(data => {
                            const jwtToken = data.token;
                            this.props.cookies.set('portalToken', jwtToken, {path: '/'})
                            Storage.set('portalToken', jwtToken)
                            callback(true)
                            API.profile()
                                .then(data => {
                                    Storage.set('portalUser', data)
                                    data.authorize = true
                                    this.props.updateUser(data)
                                })
                                .catch(e => {
                                    this.props.updateUser({authorize: false})
                                })
                        })
                        .catch(e => callback(false))
                } else {
                    API.loginPartner(email, password)
                        .then(data => {
                            const jwtToken = data.token;
                            this.props.cookies.set('token', jwtToken, {path: '/'})
                            Storage.set('token', jwtToken)
                            callback(true)
                            API.profilePartner()
                                .then(data => {
                                    Storage.set('user', data)
                                    data.authorize = true
                                    this.props.updatePartnerUser(data)
                                })
                                .catch(e => {
                                    this.props.updatePartnerUser({authorize: false})
                                })
                        })
                        .catch(e => callback(false))
                }
            })
                .catch(e => console.error(e))
        }

        this.authNext = (status) => {
            console.log(status)
        }
    }

    componentDidMount() {
        this.props.updateNavigationParam({
            page: 'login',
        })

        Utils.setPageTitle(`Восстановление аккаунта | ПабХаб`)
    }

    render() {
        let portalUser = Storage.get('portalUser')
        let partnerUser = Storage.get('user')

        const isPortalAuthorize = this.state.activeTab === 'portal' && this.props.user.authorize;
        const isPartnersAuthorize = this.state.activeTab === 'partners' && this.props.partnerUser.authorize;

        return (
            <div className='auth-container'>
                <div className='auth'>
                    <div className='auth-form'>
                        <div className='card'>
                            <div className='forgot header'>
                                <div className='label'>Забыли пароль?</div>
                                <div className='slider'>
                                    {
                                        Object.keys(this.state.tabs).map(item => {
                                            let classElement = ['element']
                                            if (item === this.state.activeTab) classElement.push('active')
                                            return <div key={this.state.tabs[item]} className={classElement.join(' ')}
                                                        onClick={() => this.setState({activeTab: item})}>
                                                {this.state.tabs[item]}
                                            </div>
                                        })
                                    }
                                </div>
                            </div>
                            <div className='content'>
                                <div className='label'>
                                    {
                                        this.state.emailSent ?
                                            'Ссылка для сброса пароля отправлена на вашу почту.' :
                                            'Укажите почту, на которую зарегистрирован аккаунт — отправим туда письмо для восстановления'
                                    }
                                </div>
                            </div>
                            {
                                this.state.emailSent ? null : <>
                                    <div className='inputs'>
                                        <div className='input'>
                                            <input placeholder='Почта' type='email' value={this.state.email}
                                                   onChange={(e) => this.setState({email: e.target.value})}/>
                                        </div>
                                    </div>
                                    <div className='button' onClick={() => this.resetPasswordStart()}>
                                        Сбросить пароль
                                    </div>
                                </>
                            }
                        </div>
                        <div className='extra'>
                            <Link className='register link' to='/register'>Регистрация</Link>
                            <Link className='forgot link' to='/login'>Вход</Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = (state, ownProps) => ({
    user: state.system.user,
    partnerUser: state.system.partnerUser,
})

const mapDispatchToProps = (dispatch, ownProps) => {
    return bindActionCreators(
        {
            updateUser: updateUser,
            updatePartnerUser: updatePartnerUser,
            updateNavigationParam: updateNavigationParam
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouterHOC(withCookies(Forgot)));