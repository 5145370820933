import React, {Component} from 'react';
import {bindActionCreators} from "redux";
import {updateNavigationParam, updateUser} from "../../redux/systemSlice";
import {connect} from "react-redux";

import './favourite.css';
import Utils from "../../utils";


class Favourite extends Component {
    constructor(props) {
        super(props);

        this.state = {}
    }

    componentDidMount() {
        this.props.updateNavigationParam({
            page: 'favourite',
        })

        Utils.setPageTitle(`Избранное | ПабХаб`)
    }

    render() {
        return (
            <div className='favourite-container'>
                <div className='favourite'>
                    <div className='name'>Избранное</div>
                    <div className='label'>
                        В разработке.... Скоро будет доступно.
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = (state, ownProps) => ({
    categories: state.system.categories
})

const mapDispatchToProps = (dispatch, ownProps) => {
    return bindActionCreators(
        {
            updateUser: updateUser,
            updateNavigationParam: updateNavigationParam,
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Favourite);