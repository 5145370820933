import React, {Component} from 'react';
import {bindActionCreators} from "redux";
import {updateCategories, updateNavigationParam, updateUser} from "../../redux/systemSlice";
import {connect} from "react-redux";
import {Link} from "react-router-dom";

import './catalog.css';
import API from "../../app/api";

import BreweriesImage from '../../images/catalog/breweries.png';
import ColorsImage from '../../images/catalog/colors.png';
import StyleImage from '../../images/catalog/styles.png';
import FilterImage from '../../images/catalog/filters.png';
import CountryImage from '../../images/catalog/countries.png';
import Utils from "../../utils";


class Catalog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            categories: {
                brewery: BreweriesImage,
                color: ColorsImage,
                style: StyleImage,
                filter: FilterImage,
                country: CountryImage,
            }
        }
    }

    componentDidMount() {
        Utils.setPageTitle('Каталог | ПабХаб')

        this.props.updateNavigationParam({
            page: 'catalog',
        })

        API.categories()
            .then(data => {
                this.props.updateCategories(data.categories)
            })
    }

    render() {
        return (
            <div className='catalog-container'>
                <div className='catalog'>
                    <div className='name'>Каталог</div>
                    <div className='categories'>
                        {
                            this.props.categories.map(category => {
                                return <Link
                                    key={category.plural_name}
                                    className='category'
                                    to={`/catalog/${category.slug}`}
                                >
                                    <div className='image'>
                                        <img
                                            src={this.state.categories[category.slug]}
                                            alt='catalog'/>
                                        <div className='label'>
                                            {category.plural_name}
                                        </div>
                                    </div>
                                </Link>
                            })
                        }
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = (state, ownProps) => ({
    categories: state.system.categories
})

const mapDispatchToProps = (dispatch, ownProps) => {
    return bindActionCreators(
        {
            updateCategories: updateCategories,
            updateUser: updateUser,
            updateNavigationParam: updateNavigationParam,
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Catalog);