import {rangeList} from "./index";

class Storage {
    static set(key, value) {
        try {
            localStorage.setItem(key, JSON.stringify(value));
        } catch (e) {
            console.error(e)
        }

    }

    static get(key, defaultValue) {
        try {
            let value = undefined
            try {
                value = JSON.parse(localStorage.getItem(key))
            } catch (e) {
            }
            if (typeof defaultValue !== "undefined" && value === null) return defaultValue
            return value
        } catch (e) {
            return defaultValue
        }

    }

    static delete(key) {
        localStorage.removeItem(key)
    }
}

class Fingerprint {
    static hashFunc(str) {
        let hash = 0, i, chr;
        if (str.length === 0) return hash;
        for (i = 0; i < str.length; i++) {
            chr = str.charCodeAt(i);
            hash = ((hash << 5) - hash) + chr;
            hash |= 0; // Convert to 32bit integer
        }
        return hash.toString(16);
    }

    static customHash(str) {
        let hashStr = rangeList(4).map(index => {
            return Fingerprint.hashFunc(String(Math.random() * index))
        })
        return Fingerprint.hashFunc(str) + hashStr.join('')
    }

    static deviceId(fingerprint) {
        let deviceId = Storage.get('GeneratedFingerprintDeviceID', null)
        if (!deviceId) {
            let deviceId = Fingerprint.customHash(fingerprint)
            Storage.set('GeneratedFingerprintDeviceID', deviceId)
            return deviceId
        }
        return deviceId
    }

    static prepareCustomData(client) {
        // client.getCustomFingerprint(...);

        // let results = FingerprintMethods.map(method => {
        //     try {
        //         let func = client[method]
        //         return {method: method, value: func()}
        //     } catch (e) {
        //         return {method: method, value: null}
        //     }
        // })

        // console.log(results)

        return 123
    }
}

export {Fingerprint};

export default Storage;