import React, {Component} from 'react';
import {bindActionCreators} from "redux";
import {updateNavigationParam, updatePartnerUser, updateUser} from "../../redux/systemSlice";
import {connect} from "react-redux";
import Utils, {withRouterHOC} from "../../utils";
import Storage from '../../utils/storage';
import './login.css';
import {Link} from "react-router-dom";
import API from "../../app/api";
import {withCookies} from "react-cookie";


class ForgotRecoveryCode extends Component {
    constructor(props) {
        super(props);

        this.state = {
            new_password: null,

            activeTab: 'portal',
            tabs: {
                portal: 'на портал',
                partners: 'партнерам'
            },

            tokenPortal: null,
            tokenPartners: null,

            recoverySuccess: false,
        }

        this.resetPasswordEnd = (callback) => {
            let token = (new URLSearchParams(this.props.location.search).get('code'))
            API.resetPasswordEnd(token, this.state.new_password)
                .then(data => {
                    console.log(data)

                    this.setState({recoverySuccess: true})

                    if (data.account_type === 'portal') {
                        const jwtToken = data.token;
                        this.props.cookies.set('portalToken', jwtToken, {path: '/'})
                        Storage.set('portalToken', jwtToken)
                        API.profile()
                            .then(data => {
                                Storage.set('portalUser', data)
                                data.authorize = true
                                this.props.updateUser(data)
                                callback(true, data.account_type)
                            })
                            .catch(e => {
                                this.props.updateUser({authorize: false})
                                callback(false)
                            })
                    } else {
                        const jwtToken = data.token;
                        this.props.cookies.set('token', jwtToken, {path: '/'})
                        Storage.set('token', jwtToken)
                        API.profilePartner()
                            .then(data => {
                                Storage.set('user', data)
                                data.authorize = true
                                this.props.updatePartnerUser(data)
                                callback(true, data.account_type)
                            })
                            .catch(e => {
                                this.props.updatePartnerUser({authorize: false})
                                callback(false)
                            })
                    }
                })
                .catch(data => {
                    console.log(data)
                })
        }

        this.authNext = (status, accountType) => {
            console.log([status, accountType])
        }
    }

    componentDidMount() {
        this.props.updateNavigationParam({
            page: 'login',
        })

        Utils.setPageTitle(`Восстановление аккаунта | ПабХаб`)
    }

    render() {
        let portalUser = Storage.get('portalUser')
        let partnerUser = Storage.get('user')

        const isPortalAuthorize = this.state.activeTab === 'portal' && this.props.user.authorize;
        const isPartnersAuthorize = this.state.activeTab === 'partners' && this.props.partnerUser.authorize;

        return (
            <div className='auth-container'>
                <div className='auth'>
                    <div className='auth-form'>
                        <div className='card'>
                            <div className='header'>
                                <div className='label'>Забыли пароль?</div>
                            </div>
                            <div className='content'>
                                <div className='label'>
                                    Укажите новый пароль для аккаунта
                                </div>
                            </div>
                            <div className='inputs'>
                                <div className='input'>
                                    <input placeholder='Новый пароль' type='password' value={this.state.new_password}
                                           onChange={(e) => this.setState({new_password: e.target.value})}/>
                                </div>
                            </div>
                            <div className='button' onClick={() => this.resetPasswordEnd(this.authNext)}>
                                Сохранить и войти на портал
                            </div>
                        </div>
                        <div className='extra'>
                            <Link className='register link' to='/register'>Регистрация</Link>
                            <Link className='forgot link' to='/login'>Вход</Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = (state, ownProps) => ({
    user: state.system.user,
    partnerUser: state.system.partnerUser,
})

const mapDispatchToProps = (dispatch, ownProps) => {
    return bindActionCreators(
        {
            updateUser: updateUser,
            updatePartnerUser: updatePartnerUser,
            updateNavigationParam: updateNavigationParam
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouterHOC(withCookies(ForgotRecoveryCode)));