import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {withRouterHOC} from "../../../utils";
import FadeComponent from "../animations/animations";

import {ReactComponent as GoToDownArrowMiniGreyIcon} from "../../../images/goToDownArrowMiniGrey.svg";
import {ReactComponent as TelegramIcon} from '../../../images/telegram.svg';

import './header.css';
import {Link} from "react-router-dom";
import {SERVER_URL} from "../../../config";
import {updateNavigationParam, updatePartnerUser, updateUser} from "../../../redux/systemSlice";
import {withCookies} from "react-cookie";
import Storage from "../../../utils/storage";


class HeaderComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isMiniProfileOpen: false
        }

        this.switchMiniProfile = (status) => {
            this.setState({isMiniProfileOpen: !this.state.isMiniProfileOpen})
        }

        this.logout = () => {
            Storage.delete('portalToken')
            this.props.cookies.remove('portalToken', {path: '/'})
            this.props.updateUser({authorize: false})
            this.setState({isMiniProfileOpen: false})
            window.location.href = `/login/`
        }
    }

    render() {

        return (
            <div className='header-component'>
                <div className='header-left'>
                    <FadeComponent isVisible={true}>
                        <div className='header-container'>
                            <a className='partners button' href='/partners'>
                                Партнёрам
                            </a>
                            <a className='telegram button' href='https://t.me/pubhubsu' target='_blank'>
                                <TelegramIcon/>
                            </a>
                        </div>
                    </FadeComponent>
                </div>
                <div className='header-right'>
                    <FadeComponent isVisible={true}>
                        <div className='header-container'>
                            {
                                this.props.navigation.page === 'login' || this.props.navigation.page === 'register' ?
                                    <>
                                        <Link className='back button' to='/'>
                                            Вернуться на портал
                                        </Link>
                                    </> :
                                    this.props.user.authorize ? <>
                                            <div className='user-container' onClick={this.switchMiniProfile}>
                                                <div className='user'>
                                                    <div className='avatar'>
                                                        <img src={this.props.user.avatar_url ?
                                                            this.props.user.avatar_url :
                                                            'https://media.pubhub.su/assets/images/avatar-default.png'}
                                                             alt='avatar'/>
                                                    </div>
                                                    <div className='name'>
                                                        {`${this.props.user.first_name} ${this.props.user.last_name}`}
                                                    </div>
                                                </div>
                                                <div className='arrow'
                                                     style={{transform: this.state.isMiniProfileOpen ? 'rotate(180deg)' : ''}}>
                                                    <GoToDownArrowMiniGreyIcon/>
                                                </div>
                                            </div>
                                        </> :
                                        <>
                                            <Link className='login button' to='/login'>
                                                Войти
                                            </Link>
                                            <Link className='register button' to='/register'>
                                                Регистрация
                                            </Link>
                                        </>
                            }
                        </div>
                    </FadeComponent>
                </div>
                {
                    this.props.user.authorize && this.state.isMiniProfileOpen ?
                        <div className='header-right-buttons'>
                            <div className='buttons'>
                                <Link className='button' to={`/user/${this.props.user.username}/`}
                                      onClick={() => this.switchMiniProfile()}>
                                    Перейти в профиль
                                </Link>
                                <div className='button' onClick={this.logout}>
                                    Выйти
                                </div>
                            </div>
                        </div> : null
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.system.user,
        navigation: state.system.navigation,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            updateUser: updateUser,
            updatePartnerUser: updatePartnerUser,
            updateNavigationParam: updateNavigationParam
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouterHOC(withCookies(HeaderComponent)));